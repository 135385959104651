import React , { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import  Home  from "../home";
import Toggle from "../../component/toggle";
import { settingsActions } from "../../actions";
import { Notfy } from "../../component";

class Settings extends Component{

    constructor(props){
        super(props);
        this.state = {
            "send_task_mail_admin_side": false,
            "send_task_mail_user_side": false,
            "send_task_sms_admin_side": false,
            "send_task_sms_user_side": false,
            "isDataChanged" : false,
            loading : false
        }
    }

    componentDidMount(){
        const { dispatch } = this.props;  
        dispatch(settingsActions.getSmsEmailNotification()); 
    }

    componentDidUpdate(prevProps){
        const { settings } = this.props;
        let settingsPropsChanged =  this.props.settings !== prevProps.settings;
        console.log(prevProps.settings, this.props.settings);

        if(settingsPropsChanged){
            if(settings.status === "GET_SMSEMAIL_SUCCESS"){
                let n = settings.notification;
                this.setState({
                    send_task_mail_admin_side : n.send_task_mail_admin_side,
                    send_task_mail_user_side : n.send_task_mail_user_side,
                    send_task_sms_admin_side : n.send_task_sms_admin_side,
                    send_task_sms_user_side : n.send_task_sms_user_side,
                })
            }
            else if(settings.status === "PUT_SMSEMAIL_SUCCESS"){
                Notfy.open({ type: "success", message: "UPDATION SUCCESSFULL"}); 
            }
            else if(settings.status.indexOf("ERROR") > -1){
                this.setState({ loading : false });
                Notfy.open({ type: "error", message: "FAILED"}); 
            }
            this.setState({ loading : settings.status.indexOf("REQUEST") > -1 });
        }
    }

    handleNotificationSubmit = () => {
        const { 
            send_task_mail_admin_side,
            send_task_mail_user_side,
            send_task_sms_admin_side,
            send_task_sms_user_side
        } = this.state;
        const { dispatch } = this.props;

        let payload = {
            send_task_mail_admin_side,
            send_task_mail_user_side,
            send_task_sms_admin_side,
            send_task_sms_user_side
        }
        dispatch(settingsActions.putSmsEmailNotification(payload));
    }

    toggleChange(e){
        this.setState({ [e.target.name] : e.target.checked, isDataChanged: true });
    }

    render(){
        const { 
            send_task_mail_admin_side,
            send_task_mail_user_side,
            send_task_sms_admin_side,
            send_task_sms_user_side,
            isDataChanged, loading
        } = this.state;

        return (
            <Home>
                <h4>Admin notification (once the customer submitted the form)</h4><br />
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <p>SMS</p>
                    <Toggle
                        checked={send_task_sms_admin_side}
                        disabled={false}
                        name="send_task_sms_admin_side"
                        size="default"
                        onChange={e => this.toggleChange(e)}
                        offstyle="btn-secondary"
                        onstyle="btn-success"
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <p>E-MAIL</p>
                    <Toggle
                        checked={send_task_mail_admin_side}
                        disabled={false}
                        name="send_task_mail_admin_side"
                        size="default"
                        onChange={e => this.toggleChange(e)}
                        offstyle="btn-secondary"
                        onstyle="btn-success"
                    />
                </div>
                <hr></hr>
                <h4>Customer notification (Once they submitted the form)</h4><br />
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <p>SMS</p>
                    <Toggle
                        checked={send_task_sms_user_side}
                        disabled={false}
                        name="send_task_sms_user_side"
                        size="default"
                        onChange={e => this.toggleChange(e)}
                        offstyle="btn-secondary"
                        onstyle="btn-success"
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <p>E-MAIL</p>
                    <Toggle
                        checked={send_task_mail_user_side}
                        disabled={false}
                        name="send_task_mail_user_side"
                        size="default"
                        onChange={e => this.toggleChange(e)}
                        offstyle="btn-secondary"
                        onstyle="btn-success"
                    />
                </div>
                {
                    isDataChanged &&
                    <div style={{display: "flex", justifyContent: "space-around", marginTop: "100px"}}>
                        <button
                            onClick={this.handleNotificationSubmit}
                            className = "save-button"
                        >
                            { loading ? "processing.." : "Save Changes" }
                        </button>
                    </div>
                }
            </Home>
        )
    }
}

function mapStateToProps(state) {
    const { settings } = state;
    return {
      settings
    };
  }
  
export default withRouter(connect(mapStateToProps)(Settings));
