export const blogConstants ={
    ALLBLOG_REQUEST:"GET_ALLBLOG_REQUEST",
    ALLBLOG_SUCCESS:"GET_ALLBLOG_SUCCESS",
    ALLBLOG_ERROR:"GET_ALLBLOG_ERROR",

    VIEW_REQUEST:"GET_VIEW_REQUEST",
    VIEW_SUCCESS:"GET_VIEW_SUCCESS",
    VIEW_ERROR:"GET_VIEW_ERROR",

    POST_REQUEST:"POST_BLOG_REQUEST",
    POST_SUCCESS:"POST_BLOG_SUCCESS",
    POST_ERROR:"POST_BLOG_ERROR",

    UPDATE_REQUEST:"UPDATE_BLOG_REQUEST",
    UPDATE_SUCCESS:"UPDATE_BLOG_SUCCESS",
    UPDATE_ERROR:"UPDATE_BLOG_ERROR",

    DELBLOG_REQUEST:"DELETE_BLOG_REQUEST",
    DELBLOG_SUCCESS:"DELETE_BLOG_SUCCESS",
    DELBLOG_ERROR:"DELETE_BLOG_ERROR",
}