import React,{Component} from 'react';
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import "../../index.css";
import { authenticationActions, taskcreateActions } from "../../actions";
// import { utils } from "../../utils/utils";

class LoginCredentials extends Component {  
    constructor(props){
       super(props);
       this.state = {
         email:'',
         password:'',
         loading: false,
         error: false,
         // redirect: utils.getParameterByName("redirect")
       }

    //   if(cookies.get('access_token'))
    //   {  let redirect = utils.getParameterByName("redirect"); 
    //      if(redirect) window.location.href= redirect;
    //      else  window.location.href = '/Inbox'; 
    //   }

       this.componentDidMount = this.componentDidMount.bind(this);
       this.onChange = this.onChange.bind(this);
       this.onSubmit = this.onSubmit.bind(this);
     }

     

     componentDidMount() {
      //  const script = document.createElement("script");
      //  script.src = "https://spiceblue.co/hellomail-new/support/hide.js";
      //  script.async = true;
      //  document.body.appendChild(script);
    //   //  this.setState({ redirect: utils.getParameterByName("redirect") });
     }

     onChange(e){
        this.setState({[e.target.name]:e.target.value, error: false});
     }

     componentWillReceiveProps(nextProps){
        const prevProps = this.props ;
        const { history } = this.props; 
        let hasPropsChanged = prevProps.authentication.status !== nextProps.authentication.status;

        if(hasPropsChanged && nextProps.authentication.status === "LOGIN_SUCCESS"){
            let data = nextProps.authentication.user;
            // if(data['token'])
            // {
            //   cookies.set('access_token', data['token'], { path: '/' });
            //   if(cookies.get('access_token'))
            //    history.push(`profile${ this.state.redirect ? "?redirect="+this.state.redirect: ""}`)
            // } else{
            //    this.setState({error : true, loading: false});
            // }
                       
        }
     }


     onSubmit(e) {
       const { dispatch } =this.props;
       const { email, password } = this.state;
        e.preventDefault();
        if(email === "" || password === "") this.setState({ error: true });
        else {
            let payload = {
               username: this.state.email,
               password: this.state.password
            }
         this.setState({ loading: true }, 
            () => dispatch(authenticationActions.login(payload, "login"))
         );
        }
                 
    }        


    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value.trim(), error: false });
    }


    render()
    {
        const { loading, error } = this.state;
        return (
           <div id="main">
           <section class="Login">
              <div class="Login__logo">
                 <span>
                 </span>
              </div>
              <h2 class="Login__title">Please log in to your account</h2>
              <div class="Login__box Login__box--progress">
                 <form class="Login__form" action="#" method="POST" >
                    <div class="Message Message-warning alert alert-block alert-warning Message--withMargin"></div>
                    <div class={ error ? "Message-danger alert alert-block alert-danger Message--withMargin " : "hide"}>
                       {
                          error && "Invalid Credentials"
                       }
                    </div>
                    <label class="Login__formRow"><span>username</span>
                    <input type="email" name="email" autocomplete="username email" required  value={this.state.email} onChange={this.onChange}/></label>
                    <label class="Login__formRow mgt2"><span>Password</span>
                    <input type="password" name="password" autocomplete="current-password" required  value={this.state.password} onChange={this.onChange}/></label>
                    <div class="Login__formActions mgt3">
                        <a href="/forgot">Forgot your password?</a>
                        <a href="/login"> Login with Mobile Number </a> 
                    </div>
                    <div className="Login__formActions">
                        <button class="btn btn-primary LeftNav__addButton--right"  onClick={this.onSubmit}>
                           { !loading ? "Log in" : "Logging in..."}
                        </button>
                    </div>
                 </form>
                 <div class="Login__footer"><span>Don't have an account?</span><a class="btn" href="signup" data-browseronly="true" data-bypass="true">Create an Account</a></div>
              </div>
           </section>
        </div>
       );   
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
  }
  
export default withRouter(connect(mapStateToProps)(LoginCredentials));