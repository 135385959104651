import { settingsConstants } from "../constants";

const initialState = {
    status: "",
    notification : {},
    error: {}
};

export function settings(state =initialState,action){
    switch(action.type){
        case settingsConstants.GET_SMSEMAIL_REQUEST:
            return{
                ...state,
                status:"GET_SMSEMAIL_REQUEST"
            };
        case settingsConstants.GET_SMSEMAIL_SUCCESS:
            return {
                ...state,
                status: "GET_SMSEMAIL_SUCCESS",
                notification: action.notify
            };
        case settingsConstants.GET_SMSEMAIL_ERROR:
            return {
                ...state,
                status: "GET_SMSEMAIL_ERROR",
                error: action.error
            };
        case settingsConstants.PUT_SMSEMAIL_REQUEST:
            return{
                ...state,
                status:"PUT_SMSEMAIL_REQUEST"
            };
        case settingsConstants.PUT_SMSEMAIL_SUCCESS:
            return {
                ...state,
                status: "PUT_SMSEMAIL_SUCCESS"
            };
        case settingsConstants.PUT_SMSEMAIL_ERROR:
            return {
                ...state,
                status: "PUT_SMSEMAIL_ERROR",
                error: action.error
            };
        default:
            return state;
    }
}