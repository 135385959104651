export const imageConstants ={
    IMAGE_REQUEST:"UPLOADING_IMAGE_REQUEST",
    IMAGE_SUCCESS:"UPLOADING_IMAGE_SUCCESS",
    IMAGE_ERROR:"UPLOADING_IMAGE_ERROR",

    ALL_REQUEST:"ALL_IMAGE_REQUEST",
    ALL_SUCCESS:"ALL_IMAGE_SUCCESS",
    ALL_ERROR:"ALL_IMAGE_ERROR",
   
    DELETE_REQUEST:"DELETE_IMAGE_REQUEST",
    DELETE_SUCCESS:"DELETE_IMAGE_SUCCESS",
    DELETE_ERROR:"DELETE_IMAGE_ERROR",
}