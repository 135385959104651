import {QuestionOptionService} from "../services";
import {QuestionOptionConstants} from "../constants";

export const QuestionOptionActions ={
    addOption,
    getOption,
    editOption,
    deleteOption
}

function addOption(payload){
    return dispatch =>{
        dispatch(request());
        QuestionOptionService.addOption(payload).then(
            message => dispatch(success(message)),
            error   => dispatch(failure(error))
        );
    };
    
    function request(){
        return {type:QuestionOptionConstants.ADD_REQUEST};
    }
    function success(message){
        return {type:QuestionOptionConstants.ADD_SUCCESS,message};
    }
    function failure(error){
        return {type:QuestionOptionConstants.ADD_ERROR,error};
    }
    
}

function getOption(questionid){
    return dispatch =>{
        dispatch(request());
        QuestionOptionService.getAllOption(questionid).then(
            message => dispatch(success(message)),
            error   => dispatch(failure(error))
        );
    };
    
    function request(){
        return {type:QuestionOptionConstants.GET_REQUEST};
    }
    function success(message){
        return {type:QuestionOptionConstants.GET_SUCCESS,message};
    }
    function failure(error){
        return {type:QuestionOptionConstants.GET_ERROR,error};
    }
    
}

function editOption(payload){
    return dispatch =>{
        dispatch(request());
        QuestionOptionService.editOption(payload).then(
            message => dispatch(success(message)),
            error   => dispatch(failure(error))
        );
    };
    
    function request(){
        return {type:QuestionOptionConstants.EDIT_REQUEST};
    }
    function success(message){
        return {type:QuestionOptionConstants.EDIT_SUCCESS,message};
    }
    function failure(error){
        return {type:QuestionOptionConstants.EDIT_ERROR,error};
    }
    
}

function deleteOption(id){
    return dispatch =>{
        dispatch(request());
        QuestionOptionService.deleteOption(id).then(
            message => dispatch(success(message)),
            error   => dispatch(failure(error))
        );
    };
    
    function request(){
        return {type:QuestionOptionConstants.DELETE_REQUEST};
    }
    function success(message){
        return {type:QuestionOptionConstants.DELETE_SUCCESS,message};
    }
    function failure(error){
        return {type:QuestionOptionConstants.DELETE_ERROR,error};
    }
    
}
