import React,{Component} from "react";
import utils from "../../utils/utils.js";
import "./index.css";

class PricingCard extends Component {

    render(){
        const { 
            details = {}, 
            onclick = () => null, 
            disableButton = false,
            buttonText = "Subscribe & Pay",
            plan="",
            plan_name= "",
            active= "",
            session= "",
            changedPlan = () => null 
         } = this.props;
        return (
            <div>
                    <div className="mycard" style={{backgroundColor: "#fff"}}>
                         <div className="title">
                         <i class={details.icon} aria-hidden="true" style={{color: "#444"}}></i>
                           <h4 style={{color: "#444"}}>{details.title}</h4>
                         </div>
                        <div class="price">
                         <h6 style={{color: "#444"}}> {details.price_symbol.toUpperCase()}{" "+details.price}</h6>
                        </div>
                        <div className="option">
                        <ul>
                       
                            {details.options.map(e=>{
                                return(
                                 <li style={{color: "#444"}} ><i class="fa fa-check" aria-hidden="true" style={{color:"green"}}></i> {e}</li>
                            )})}
                        </ul>
                        {   disableButton && plan_name === details.plan_id &&
                            <p style={{color: "#5b3", textAlign: "center"}}>
                                Current Plan
                            </p>
                        }
                        <a >
                            {   !disableButton &&
                                <button onClick={() => onclick(details.plan_id)}>
                                    {plan === details.plan_id ? buttonText : "Subscribe & Pay"}
                                </button>
                            }
                            {   disableButton && !active &&
                                <button
                                    onClick={
                                        // plan_name === details.plan_id ?
                                        // () => utils.paymentRedirection(session) :
                                        () => changedPlan({plan_id: details.plan_id})
                                    }
                                >
                                    { plan_name === details.plan_id ? "Pay" : "Change & Pay" }
                                </button>
                            }
                            {   disableButton && active && 
                                plan_name !== details.plan_id &&
                                <button 
                                    onClick={() => changedPlan({plan_id: details.plan_id})}
                                >
                                    {"Change & Pay" }
                                </button>
                            }
                        </a>
                       </div>
                    </div>
    </div>
)
}
}
export default PricingCard;