import {blogConstants} from "../constants";

const initialState ={
    status:"",
    blogs:[]
};

export function blog(state =initialState,action){
    switch(action.type){
        case blogConstants.ALLBLOG_REQUEST:
            return{
                ...state,
                status:"GET_ALLBLOG_REQUEST"
            };
        case blogConstants.ALLBLOG_SUCCESS:
            return {
                ...state,
                status:"GET_ALLBLOG_SUCCESS",
                blogs:action.message
            };
        case blogConstants.ALLBLOG_ERROR:
            return {
                ...state,
                status:"GET_ALLBLOG_ERROR",
                blogs:action.error
            };
        case blogConstants.VIEW_REQUEST:
            return {
                ...state,
                status:"GET_VIEW_REQUEST"
            };
        case blogConstants.VIEW_SUCCESS:
            return {
                ...state,
                status:"GET_VIEW_SUCCESS",
                blog:action.message
            };
        case blogConstants.VIEW_ERROR:
            return {
                ...state,
                status:"GET_VIEW_ERRROR",
                blogs:action.error
            };
        case blogConstants.POST_REQUEST:
            return {
                ...state,
                status:"POST_BLOG_REQUEST"
            };
        case blogConstants.POST_SUCCESS:
            return {
                ...state,
                status:"POST_BLOG_SUCCESS",
                blog:action.message
            };
        case blogConstants.POST_ERROR:
            return {
                ...state,
                status:"POST_BLOG_ERROR",
                blogs:action.error
            };
        case blogConstants.UPDATE_REQUEST:
                return {
                    ...state,
                    status:"UPDATE_BLOG_REQUEST"
                };
        case blogConstants.UPDATE_SUCCESS:
                return {
                    ...state,
                    status:"UPDATE_BLOG_SUCCESS",
                    blog:action.message
                };
        case blogConstants.UPDATE_ERROR:
                return {
                    ...state,
                    status:"UPDATE_BLOG_ERROR",
                    blogs:action.error
                };
        case blogConstants.DELBLOG_REQUEST:
                    return {
                        ...state,
                        status:"DELETE_BLOG_REQUEST"
                    };
        case blogConstants.DELBLOG_SUCCESS:
                    return {
                        ...state,
                        status:"DELETE_BLOG_SUCCESS",
                        blog:action.message
                    };
        case blogConstants.DELBLOG_ERROR:
                    return {
                        ...state,
                        status:"DELETE_BLOG_ERROR",
                        blogs:action.error
                    };
            
        default:
            return state;
    }
}