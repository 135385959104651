import React,{Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import utils from "../../utils/utils.js";
import Home from "../home";
import {getCustomer} from "../../actions/customerSlice";
import {fetchget} from "../../actions/csvSlice";
import notyf from '../../utils/notification';
import { TopBarLoader } from '../../component';
import CustomerTable from '../../component/customertable/index.js';

class Customer extends Component {
    constructor(props){
    super(props);
    this.state ={

    }
    if(!utils.isLoggedIn()){
        window.location.href = "/login";
    }
  }

  componentWillReceiveProps(nxtProps){
       const {csv,companies,customer,dispatch} = nxtProps;
       let companyPropsChanged = companies.status !== this.props.companies.status;
       if(companyPropsChanged && companies.status === "GET_SUCCESS")
       {
          dispatch(getCustomer());
         
       }
       if(nxtProps.csv.status==="GET_USER_MAIL_SUCCESS"){
           notyf.open({type:"success",message:csv.csv.message});
       }
       if(nxtProps.csv.status==="GET_USER_MAIL_ERROR"){
           notyf.open({type:"error", message:csv.csv.message});
       }
   }

  render(){
      const {dispatch,csv,customer}=this.props;
      document.title="Customer-MoonProduct"
      return(
          <Home>
              {(csv.status==="GET_USER_MAIL_REQUEST"||customer.status==="GET_CUSTOMER_LIST_REQUEST") && <TopBarLoader/>}
              <div>
                  <h1>Customer List</h1>
                  <p style={{textTransform:"capitalize",textAlign:"center"}}>Now, You can Download the customers Details As a csv file in Your Register Mail Id</p>
              
                   <button onClick={()=>dispatch(fetchget())} rel="tooltip" data-original-title="Download Csv File" >
                     <i class="material-icons" style={{verticalAlign:"middle"}}>system_update_alt</i>&nbsp;Download Customer List as .CSV File</button>
          
              </div>
              {customer.status==="GET_CUSTOMER_LIST_SUCCESS" &&
              <CustomerTable content={customer.customer.users}/>
              }
          </Home>
      )
  }
 
}

function mapStateToProps(state){
    const {csv,companies,customer}=state;
    return {
        csv,companies,customer
    }
}

export default withRouter(connect(mapStateToProps)(Customer))