import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import "../home/index.css";
import Home from "../home";
import {taskstatusActions,taskviewActions, companyActions} from "../../actions";
import notyf from "../../utils/notification";

class CompaniesLoader extends Component {
    constructor() {
        super();
    }

    componentWillMount() {
        const {dispatch,history} =this.props;
        let payload={};
        dispatch(companyActions.getCompanies())
    }

    componentDidUpdate(newProps) {
      const { companies, history, dispatch } =newProps;

      if(companies.status ==="GET_SUCCESS"){
          history.push("/taskboard")
      }
    }

    render()
    {
        return(
        <div style={{ alignItems: "center", flex: 1, justifyContent: "center",backgroundColor:"#fffff" }}>
          {/* <Loader/> */}
        </div>
      );
    }
    
}
function mapStateToProps(state) {
    const { companies } = state;
    return {
      companies
    };
  }
  
  export default withRouter(connect(mapStateToProps)(CompaniesLoader));
