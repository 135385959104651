import React,{Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import utils from "../../utils/utils.js";
import Home from "../home";
import SimpleMDEReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import notyf from '../../utils/notification';

import { blogActions, imageActions } from '../../actions';
import {imageService} from "../../services/image.service"
import "./index.scss";
import { TopBarLoader } from '../../component';

class CreateNewBlog extends Component{
    constructor(props){
        super(props);
        this.state ={
           imageurl:"",
           content:"",
           description:"",
           title:"",
           Changed:false,
           getImageurl:false
        };
      if(!utils.isLoggedIn()){
            window.location.href = "/login";
        }
    }

    handlemdeChange = value => {
      this.setState({ content: value,Changed:true});
    };

    onChange=e=>{
        this.setState({[e.target.name]:e.target.value, Changed: true});
        if(e.target.name==="imageurl"){
          this.setState({getImageurl:true})
        }
     }

     onSubmit=()=>{
       const {dispatch}=this.props;
      let payload={
        "title":this.state.title,
        "description":this.state.description,
        "image_url":this.state.imageurl,
        "content":this.state.content,
        "tags":[]
      }
      dispatch(blogActions.postblog(payload))
     }
    
    // handleImage=(event)=> {
    //     const {dispatch } =this.props;
    //     let preview = document.querySelector("img");
    //     let image = "";
    //     let j = 0;
    //     let data = document.querySelector("input[type=file]").files;  
    //     for (let i = 0; i < data.length; i++) {
    //       let file =data[i];
    //       
    //       let reader = new FileReader();
    //       reader.addEventListener(
    //         "load",
    //         function() {
    //           preview.src = reader.result;
    //         },
    //         false
    //       );
          
    //       if (file) {
    //         reader.readAsDataURL(file);
    //         dispatch(imageActions.UploadImage(file))
    //       }
    //     }
      
    //   }
    
    componentWillReceiveProps(nxtProps){
      const {blog} = nxtProps;
      const {history}=this.props;
      let blogPropsChanged = blog.status !== this.props.blog.status;
      if(nxtProps.blog.status==="POST_BLOG_SUCCESS")
      {
        notyf.open({type:"success", message:"Your Blog has been posted to your website"});
        history.push("/admin/listblogs")
      }
      if(nxtProps.blog.status==="POST_BLOG_ERROR")
      {
        notyf.open({type:"Error", message:"Sorry,We Can't post your blog in your website"});
      }
     
    }

render(){
  const {blog} =this.props;
    return(
        <Home>
        <div>
          {blog.status==="POST_BLOG_REQUEST" && <TopBarLoader/>}
          <div>
            <h1 style={{marginTop:"5rem"}}>Create Your Amazing Blog Post Here</h1>
            </div>
            {this.state.Changed && 
             <div>
                  <button onClick={()=>this.onSubmit()}>Post In Your Website</button>
             </div>
            }
            <div>
            <p style={{bottom:0,marginTop:"5rem",fontSize:"1rem"}}>Add Your Blog Image Url Here</p>
            <input
             type="url"
             name="imageurl"
             style={{width:"100%",marginTop:0,height:"50px"}}
             onChange={(e) => this.onChange(e)}
             value={this.state.imageurl}/>
           {this.state.getImageurl&&
           <div className="image-upload">
            <div className="image-preview">
             <img src={this.state.imageurl}/>
            </div> 
           </div>}

       {/* Image Upload Via S3 Bucket */}
        {/* <div className="image-upload">
        <div className="image-edit">
        <input
           id="imageUpload"
            type="file"
            name="fileupload"
            required
            enctype="multipart/form-data"
            onChange={this.handleImage}
            accept="image/png, image/gif, image/jpeg"
          />
            <label for="imageUpload"></label>
        </div>
        <div className="image-preview">
            <img src="http://knock.moonproduct.in:5000/admin/image/7d78e811cd88f6c67885/download(1).jpg"/>
          </div>  
         </div> */}
           {/**************************END***************************** */}
        
          <p style={{bottom:0,marginTop:"5rem",fontSize:"1rem"}}>Write Your Blog Title Here</p>
          <input
          type={"text"}
          name="title"
          style={{width:"100%",marginTop:0,height:"50px"}}
          onChange={(e) => this.onChange(e)}
          value={this.state.title}/>

         <p style={{bottom:0,marginTop:"1rem",fontSize:"1rem"}}>Write Your Short Descripition about your blog Here</p>
         <input
         type={"text"}
         name="description"
         style={{width:"100%",marginTop:0,height:"50px"}}
         onChange={(e) => this.onChange(e)}
         value={this.state.description}/>
       </div>
      <div>
        <SimpleMDEReact
         label="Make Your Blog Content Here"
         onChange={this.handlemdeChange}
         value={this.state.content}
         options={{
              autofocus: true,
              spellChecker: false,
              hideIcons:["side-by-side","fullscreen"]}}/>
         </div>
   </div>
        {this.state.Changed && 
          <div>
             <div>
                  <button onClick={()=>this.onSubmit()} style={{float:"left"}}>Post In Your Website</button>
               </div>
          </div>
        }
  </Home>
    )
}

}



function mapStateToProps(state) {
    const { admin, user,blog,companies,image,authentication } = state;
    return { admin, user,blog ,companies,image,authentication};
}
  
export default withRouter(connect(mapStateToProps)(CreateNewBlog));