export const QuestionOptionConstants ={
    ADD_REQUEST:"ADD_OPTION_REQUEST",
    ADD_SUCCESS:"ADD_OPTION_SUCCESS",
    ADD_ERROR:"ADD_OPTION_ERROR",
    
    GET_REQUEST:"GET_OPTION_REQUEST",
    GET_SUCCESS:"GET_OPTION_SUCCESS",
    GET_ERROR:"GET_OPTION_ERROR",

    EDIT_REQUEST:"EDIT_OPTION_REQUEST",
    EDIT_SUCCESS:"EDIT_OPTION_SUCCESS",
    EDIT_ERROR:"EDIT_OPTION_ERROR",

    DELETE_REQUEST:"DELETE_OPTION_REQUEST",
    DELETE_SUCCESS:"DELETE_OPTION_SUCCESS",
    DELETE_ERROR:"DELETE_OPTION_ERROR",
}