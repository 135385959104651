import {companyService} from "../services";
import {companyConstants} from "../constants";

export const companyActions ={
    getCompanies,
};

function getCompanies(){
    return dispatch =>{
        dispatch(request());
        companyService.getCompanies()
        .then(res => dispatch(success(res)),err =>dispatch(error(err)));
    };
    function request(){
        return {type: companyConstants.GET_REQUEST};
    }
    function success(res){
        return{type:companyConstants.GET_SUCCESS,res};
    }
    function error(err){
        return{type:companyConstants.GET_ERROR, err};
    }
}