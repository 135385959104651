import React, { Component } from "react";
import { ChromePicker, SliderPicker } from 'react-color';

class ColorPickerInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: -1,
        }
        // this.onColorSelect = this.onColorSelect.bind(this);
    }

    handleClick = (key) => {
        const { displayColorPicker } = this.state;
        this.setState({ displayColorPicker: displayColorPicker === -1 ? key : -1 });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: -1 })
    };

    render() {
        const {
            colorKey = "",
            onColorSelect = () => null,
            defaultColor = "#000000",
            textToShow
        } = this.props;
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        return (
            <div  key={colorKey} id={colorKey} onClick={() => this.handleClick(colorKey)} style={{ width: "30px", height: "25px", backgroundColor: defaultColor }}>
                { this.state.displayColorPicker === colorKey ?
                    <div style={popover} key={colorKey}>
                        <span style={{backgroundColor:"#FFF"}}>{textToShow}</span>
                        <div style={cover} onClick={this.handleClose} />
                        <ChromePicker
                            onChange={(color) => onColorSelect(color, colorKey)}
                            onChangeComplete={(color) => onColorSelect(color, colorKey)}
                            color={defaultColor}
                        />
                    </div> :
                    null}
            </div>
        );
    }
}

export default ColorPickerInput;