import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import '../../index.css';
import utils from "../../utils/utils.js";
import { widgetActions } from '../../actions';
import { ColorPickerInput, TextInput } from "../../component";
import Home from "../home";
import IframeCode from "./iframecode";
import notyf from '../../utils/notification';



class FormConfig extends Component {  
    constructor(props){
       super(props);
       this.state = {
           error: false,
           colorPicker: -1,
           widgetJson : {},
           widgetKeys: [],
           fieldChanged : false
        }

        if(!utils.isLoggedIn()){
            window.location.href = "/login";
        }

    }

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(widgetActions.getJson());
    }

    componentDidUpdate(prevProps){
        const { widget } = this.props;
        let widgetPropsChanged = widget.status !== prevProps.widget.status;

        if(widgetPropsChanged){
            if(widget.status === "GET_JSON_SUCCESS"){
                let widgetKeys = Object.keys(widget.config)
                this.setState({ widgetJson: widget.config, widgetKeys: widgetKeys });
            }
        }
        
    }

    handleChange(jsKey, value){
        let jso = this.state.widgetJson;
        jso[jsKey]["value"] = value;
        this.setState({ widgetJson: jso, fieldChanged: true });
    }

    onColorSelect = ( color, key ) => {
        let jso = this.state.widgetJson;
        jso[key]["value"] = color.hex;
        this.setState({ widgetJson: jso, fieldChanged: true });
    }

    renderkeys = ( jsonKey, values ) => {
        switch(values.type){
            case "color" : return <ColorPickerInput 
                                colorKey = {jsonKey} 
                                onColorSelect = {this.onColorSelect}
                                defaultColor={values.value}
                            />;
            case "text": return <TextInput
                                    type={"text"}
                                    name={jsonKey}
                                    onChange={(textValue) => this.handleChange(jsonKey, textValue.target.value)}
                                    value={values.value}
                                />
            default : return <div></div>;
        }
    }

    render() {
        const { widget } = this.props;
        const { widgetJson, widgetKeys } = this.state;
        return(
            <div>
                {
                   widgetKeys.length !== 0 &&
                   widgetKeys.map( singleKey => {
                       return (
                            <div>{this.renderkeys(singleKey, widgetJson[singleKey])}</div>
                       )
                   }) 
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { admin, widget } = state;
  
    return { admin,  widget };
  }
  
export default withRouter(connect(mapStateToProps)(FormConfig));