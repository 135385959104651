import React,{Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import utils from "../../utils/utils.js";
import Home from "../home";
import SimpleMDEReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import notyf from '../../utils/notification';

import { blogActions, imageActions } from '../../actions';
import "./index.scss";
import { TopBarLoader } from "../../component";

class EditBlog extends Component {
    constructor(props){
        super(props);
        this.state ={
            blogs:{},
            Changed:false,
            Processing:false
        };
        if(!utils.isLoggedIn()){
            window.location.href = "/login";
        }
    }

    componentDidUpdate(prevProps){
     const {history,blog,companies,dispatch}=this.props;
     let url = this.props.match.params.url;
     let blogPropsChanged =blog.status !=prevProps.blog.status;
     let companyPropsChanged = companies.status !== prevProps.companies.status;
   
     if(companyPropsChanged && companies.status === "GET_SUCCESS")
     {
        dispatch(blogActions.Viewblog(url))
     }
     if(blogPropsChanged){
         if(blog.status==="GET_VIEW_SUCCESS"){
             this.setState({blogs:blog.blog,Processing:true,Changed:false})
         }
         if(blog.status==="GET_VIEW_ERROR"){
            let message=blog.blog.message
            notyf.open({type:"error",message:message});
        }
         if(blog.status==="UPDATE_BLOG_SUCCESS"){
            let message=blog.blog.message
            notyf.open({type:"success",message:message});
             history.push("/admin/listblogs")
         }
         if(blog.status==="UPDATE_BLOG_ERROR"){
             let message=blog.blog.message
            notyf.open({type:"error",message:message});
        }
     }
    }
    handlemdeChange = value => {
        let jso = this.state.blogs;
        jso["content"]=value;
        this.setState({ blogs:jso,Changed:true});
      };
  
    onChange(e){
        let jso = this.state.blogs;
        jso[e.target.name]=e.target.value;
        this.setState({ blogs: jso, Changed: true });
    }
    onSubmit=()=>{
        const {dispatch}=this.props;
        const {blogs}=this.state;
       let payload={
            "title":blogs.title,
            "description":blogs.description,
            "image_url":blogs.image,
            "content":blogs.content,
            "id":blogs.id
         }
       dispatch(blogActions.updateblog(payload))
      }
     
    render(){
        const {blogs} =this.state;
        const {dispatch,blog}=this.props;
        let url = this.props.match.params.url;
        return(
            <Home>{blog.status==="GET_VIEW_REQUEST"&& <TopBarLoader/>}
            {this.state.Processing
            && 
          <div>
              <div>
                <h1 style={{marginTop:"5rem"}}>Edit your Amazing Blog Post Here</h1>
                </div>
                {this.state.Changed&& 
                 <div>
                      <button onClick={()=>this.onSubmit()} >Update your post</button>
                 </div>
                }
                <div>
                <p style={{bottom:0,marginTop:"5rem",fontSize:"1rem"}}>Add Your Blog Image Url Here</p>
                <input
                 type="url"
                 name="image"
                 style={{width:"100%",marginTop:0,height:"50px"}}
                 onChange={(e) => this.onChange(e)}
                 value={blogs.image}/>
              
               <div className="image-upload">
                <div className="image-preview">
                 <img src={blogs.image}/>
                </div> 
               </div>
    
           {/* Image Upload Via S3 Bucket */}
            {/* <div className="image-upload">
            <div className="image-edit">
            <input
               id="imageUpload"
                type="file"
                name="fileupload"
                multiple
                required
                onChange={this.handleImage}
                accept="image/png, image/gif, image/jpeg"
              />
                <label for="imageUpload"></label>
            </div>
            <div className="image-preview">
                <img src="http://knock.moonproduct.in:5000/admin/image/7d78e811cd88f6c67885/download(1).jpg"/>
              </div>  */}
             {/* </div> */}
               {/**************************END***************************** */}
            
              <p style={{bottom:0,marginTop:"5rem",fontSize:"1rem"}}>Write Your Blog Title Here</p>
              <input
              type={"text"}
              name="title"
              style={{width:"100%",marginTop:0,height:"50px"}}
              onChange={(e) => this.onChange(e)}
              value={blogs.title}/>
    
             <p style={{bottom:0,marginTop:"1rem",fontSize:"1rem"}}>Write Your Short Descripition about your blog Here</p>
             <input
             type={"text"}
             name="description"
             style={{width:"100%",marginTop:0,height:"50px"}}
             onChange={(e) => this.onChange(e)}
             value={blogs.description}/>
           </div>
          <div>
            <SimpleMDEReact
             label="Make Your Blog Content Here"
             onChange={this.handlemdeChange}
             value={blogs.content}
             options={{
                  autofocus: true,
                  spellChecker: false,
                  hideIcons:["side-by-side","fullscreen"]}}/>
             </div>
      
            {this.state.Changed && 
              <div style={{marginTop:40}}>
                 <div>
                      <button onClick={()=>this.onSubmit()} style={{float:"left"}}>Update  Your Blog</button>
                   </div>
                   <div>
                      <button onClick={()=>dispatch(blogActions.Viewblog(url))} style={{float:"right",backgroundColor:"#f03a3a",marginRight:"40px"}}>cancel</button>
                   </div>
              </div>
            }
             </div>}
      </Home>
        )
    }
    
    }
    
    
    
function mapStateToProps(state) 
{
        const { admin, user,blog,companies,image,authentication } = state;
        return { admin, user,blog ,companies,image,authentication};
}

    

export default withRouter(connect(mapStateToProps)(EditBlog));
