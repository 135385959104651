import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import '../../index.css';
import utils from "../../utils/utils.js";
import { widgetActions } from '../../actions';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import notyf from '../../utils/notification';
import country from "./phone.json";
import fromquestions from "./fromquestions.json";
import font from "./font.json";
import Toggle from "../../component/toggle";
import { ColorPickerInput, TextInput } from "../../component";

class Widgetform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            colorPicker: -1,
            widgetJson: {},
            widgetKeys: [],
            // active:"your Phone number will be shown on widget",
            // activewhatsapp:"your WhatsApp number will be shown on widget",
            // activereferral:"Your Referral code process will be activated",
            // activeemailid:"Now,you will get your customers EmailId",
            disabledreferral: "Upgrade to Half Moon to enable this feature.",
            fieldChanged: false
        }

        if (!utils.isLoggedIn()) {
            window.location.href = "/login";
        }
    }

    UNSAFE_componentWillMount() {
        const { dispatch } = this.props;
        dispatch(widgetActions.getJson());
    }
    componentDidUpdate(prevProps) {
        const { widget, dispatch } = this.props;
        let widgetPropsChanged = widget.status !== prevProps.widget.status;

        if (widgetPropsChanged) {
            if (widget.status === "GET_JSON_SUCCESS") {
                let widgetKeys = Object.keys(widget.config)
                this.setState({ widgetJson: widget.config, widgetKeys: widgetKeys });

            }
            if (widget.status === "UPDATE_JSON_SUCCESS") {
                notyf.open({ type: "success", message: "Your Changes has been updated" })
                this.setState({ fieldChanged: false })
                dispatch(widgetActions.getJson());
            }
            if (widget.status === "UPDATE_JSON_ERROR") {
                notyf.open({ type: "error", message: "Sorry!,Your Changes has not been updated" })
                this.setState({ fieldChanged: false })
                dispatch(widgetActions.getJson());
            }
        }
    }
    addons = () => {
        const { dispatch, widget } = this.props;
        let payload = {
            "Phone": widget.config.Phone,
            "bg_color": widget.config.bg_color,
            "border_radius": widget.config.border_radius,
            "button_color": widget.config.button_color,
            "font_family": widget.config.font_family,
            "home-country-code": widget.config["home-country-code"],
            "phone_show": widget.config.phone_show,
            "primary_color": widget.config.primary_color,
            "primarytextcolor": widget.config.primarytextcolor,
            "referral": widget.config.refferral,
            "secondary_color": widget.config.secondary_color,
            "secondarytextcolor": widget.config.secondarytextcolor,
            "whatsapp_show": widget.config.whatsapp_show,
            "referral": widget.config.referral,
            "header": widget.config.header === "undefined" ? widget.config.header : { type: "text", value: "contact us" },
            "emailid_India": widget.config.emailid_India === "undefined" ? widget.config.emailid_India : { type: "checkbox", value: true },
            "greetings": { type: "text", value: "ThankYou" },

        }
        dispatch(widgetActions.updateJson(payload))
    }
    onCancel = () => {
        const { dispatch } = this.props;
        dispatch(widgetActions.getJson())
        this.setState({ fieldChanged: false })
    }
    onSubmit = () => {
        const { dispatch } = this.props;
        let payload = this.state.widgetJson
        if (payload["phone_show"].value === true && isValidPhoneNumber(payload["Phone"].value)) {
            dispatch(widgetActions.updateJson(payload))
        }
        else if (payload["phone_show"].value !== true) {
            dispatch(widgetActions.updateJson(payload))
        }
        else {
            notyf.open({ type: 'error', message: "Enter valid Phone number with country code." });
        }
    }
    toggleChange(e) {
        let jso = this.state.widgetJson;
        jso[e.target.name]["value"] = e.target.checked;
        this.setState({ widgetJson: jso, fieldChanged: true });
        // if(e.target.name==="phone_show"){
        // this.setState(prevState =>({active:prevState.active==="your Phone number will be shown on widget" ?"your Phone number will not shown on widget":"your Phone number will be shown on widget"}))}
        // if(e.target.name==="whatsapp_show"){
        //     this.setState(prevState =>({activewhatsapp:prevState.activewhatsapp=== "your Whatsapp not shown on widget"?"your Whatsapp will  be shown on widget":"your Whatsapp not shown on widget"}))  
        // }
        // if(e.target.name==="referral"){
        //     this.setState(prevState =>({activereferral:prevState.activereferral==="Your Referral code process will be activated" ?"Your Referral code  deactivated":"Your Referral code process will be activated"}))  
        // }
        // if(e.target.name==="emailid_India"){
        //     this.setState(prevState =>({activeemailid:prevState.activeemailid==="Now,you will get your customers EmailId"  ?"You Can't get customers EmailId":"Now,you will get your customers EmailId" }))
        // }
    }
    PhoneChange(e) {
        let jso = this.state.widgetJson;
        jso["Phone"]["value"] = e;
        this.setState({ widgetJson: jso, fieldChanged: true });
    }
    handleChange(e) {
        let jso = this.state.widgetJson;
        jso[e.target.name]["value"] = e.target.value;
        this.setState({ widgetJson: jso, fieldChanged: true });
    }
    onColorSelect = (color, key) => {
        let jso = this.state.widgetJson;
        jso[key]["value"] = color.hex;
        this.setState({ widgetJson: jso, fieldChanged: true });
    }


    render() {
        const { widget, dispatch } = this.props;
        const { widgetJson, widgetKeys } = this.state;
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                    <h1 style={{ textAlign: "center", fontSize: "16px", lineHeight: "3.5em", top: "10px" }}>Your widget configurations</h1>
                    {this.state.fieldChanged &&
                        <div>
                            <button onClick={() => this.onSubmit()} className="mpbutton" >Update</button>
                        </div>}
                    {this.state.fieldChanged && <div >
                        <button onClick={() => this.onCancel()} className="mpbutton">Cancel</button>
                    </div>}
                </div>

                {widget.status === "GET_JSON_SUCCESS" && widgetKeys.length !== 0 && <div>
                    {typeof widget.config["header"] === "undefined" && this.addons()}
                    {typeof widget.config["emailid_India"] === "undefined" && typeof widget.config["greetings"] && this.addons()}
                    <p>{fromquestions["header"]}</p>
                    {typeof widgetJson["header"] !== "undefined" && <TextInput
                        type={"text"}
                        name="header"
                        style={{ width: "50%" }}
                        onChange={(e) => this.handleChange(e)}
                        value={widgetJson["header"].value}
                    />}
                    <hr></hr>
                    <p>{fromquestions["home-country-code"]}</p>
                    <select name="home-country-code" onChange={e => this.handleChange(e)} value={widgetJson["home-country-code"].value}>
                        {country.map(i => {
                            return (
                                <option data-thumbnail={i.flag} value={i.number}>{i.name}</option>
                            )
                        })}
                    </select>
                    <hr></hr>
                    <p>Colors</p>
                    <div style={{ display: "flex",padding:1 }}>
                        <ColorPickerInput
                            colorKey="primary_color"
                            onColorSelect={this.onColorSelect}
                            defaultColor={widgetJson["primary_color"].value}
                            textToShow= {fromquestions["primary_color"]}
                        />
                        <ColorPickerInput
                            colorKey="secondary_color"
                            onColorSelect={this.onColorSelect}
                            defaultColor={widgetJson["secondary_color"].value}
                            textToShow= {fromquestions["secondary_color"]}
                        />
                        <ColorPickerInput
                            colorKey="primarytextcolor"
                            onColorSelect={this.onColorSelect}
                            defaultColor={widgetJson["primarytextcolor"].value}
                            textToShow= {fromquestions["primarytextcolor"]}
                        />
                        <ColorPickerInput
                            colorKey="secondarytextcolor"
                            onColorSelect={this.onColorSelect}
                            defaultColor={widgetJson["secondarytextcolor"].value}
                            textToShow= {fromquestions["secondarytextcolor"]}
                        />
                        <ColorPickerInput
                            colorKey="bg_color"
                            onColorSelect={this.onColorSelect}
                            defaultColor={widgetJson["bg_color"].value}
                            textToShow= {fromquestions["bg_color"]}
                        />
                        <ColorPickerInput
                            colorKey="button_color"
                            onColorSelect={this.onColorSelect}
                            defaultColor={widgetJson["button_color"].value}
                            textToShow= {fromquestions["button_color"]}
                        />
                    </div>
                    <hr></hr>
                    <p>{fromquestions["font_family"]}</p>
                    <select name="font_family" onChange={e => this.handleChange(e)} value={widgetJson["font_family"].value}>
                        {font.families.map(i => {
                            return (
                                <option value={i.toUpperCase()}>{i}</option>
                            )
                        })}
                    </select>
                    <hr></hr>
                    <p>{fromquestions["phone_show"]}</p>
                    <Toggle
                        checked={widgetJson["phone_show"].value}
                        name="phone_show"
                        //   text={this.state.active}
                        onChange={e => this.toggleChange(e)}
                        offstyle="btn-danger"
                        onstyle="btn-success"
                    />
                    <hr></hr>
                    {widgetJson["phone_show"].value &&
                        <div>
                            <p>{fromquestions["Phone"]}</p>
                            <PhoneInput
                                name="Phone"
                                placeholder="Enter phone number with country code."
                                value={widgetJson["Phone"].value}
                                onChange={e => this.PhoneChange(e)}
                            />
                            <hr></hr>
                        </div>}

                    {widgetJson["phone_show"].value === false && <p>{fromquestions["whatsapp_show"]}</p>}
                    {widgetJson["phone_show"].value && <p>{fromquestions["whatsapp_show"]}</p>}
                    <Toggle
                        checked={widgetJson["whatsapp_show"].value}
                        name="whatsapp_show"
                        //   text={this.state.activewhatsapp}
                        onChange={e => this.toggleChange(e)}
                        offstyle="btn-danger"
                        onstyle="btn-success"
                    />
                    <hr></hr>
                    <p>{fromquestions["border_radius"]}</p>
                    <TextInput
                        type={"text"}
                        name="border_radius"
                        style={{ width: "50%" }}
                        onChange={(e) => this.handleChange(e)}
                        value={widgetJson["border_radius"].value}
                    />
                    <hr></hr>
                    <p>{fromquestions["referral"]}</p>
                    <Toggle
                        checked={widgetJson["referral"].value}
                        disabled={true}
                        name="referral"
                        size="default"
                        text={this.state.disabledreferral}
                        onChange={e => this.toggleChange(e)}
                        offstyle="btn-danger"
                        onstyle="btn-success"
                    />
                    <hr></hr>
                    {typeof widgetJson["emailid_India"] !== "undefined" &&
                        <div>
                            <p>{fromquestions["emailid_India"]}</p>
                            <Toggle
                                checked={widgetJson["emailid_India"].value}
                                disabled={false}
                                name="emailid_India"
                                size="default"
                                //   text={this.state.activeemailid}
                                onChange={e => this.toggleChange(e)}
                                offstyle="btn-danger"
                                onstyle="btn-success"
                            /><hr></hr>
                        </div>
                    }
                    {typeof widgetJson["greetings"] !== "undefined" &&
                        <div>
                            <p>{fromquestions["greetings"]}</p>
                            <TextInput
                                type={"text"}
                                name="greetings"
                                style={{ width: "50%" }}
                                onChange={(e) => this.handleChange(e)}
                                value={widgetJson["greetings"].value}
                            />

                            <hr></hr>
                        </div>}
                </div>}
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { admin, widget } = state;
    return { admin, widget };
}

export default withRouter(connect(mapStateToProps)(Widgetform));