import Store from "../reducers";
import appData from "../app.json";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(appData.publishing_key);

const utils = {
    ValidateMobileNumber,
    getParameterByName,
    isLoggedIn,
    paymentRedirection
};


function ValidateMobileNumber (mobile) {
    let pattern = "[+]{1}[0-9]{12}";
    if(mobile.match(pattern)) return true;
    else return false;
}

function getParameterByName(name, url) {
    
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));

} 

function isLoggedIn() {
    return typeof Store.getState().authentication.user !== "undefined";
}

async function paymentRedirection(sessionId){
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
    console.log(error);
}

export default utils;