import React,{Component} from 'react';
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { authenticationActions } from "../../actions";


class Logout extends Component {  
    constructor(props){
       super(props);
       this.state = {}


       this.componentDidMount = this.componentDidMount.bind(this);
     }

     

     componentDidMount() {
        const { dispatch, history } = this.props;
        dispatch(authenticationActions.logout());
        history.push("/login");
     }


    render()
    {
        const {} = this.state;
        return (
           <div></div>
       );   
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
  }
  
export default withRouter(connect(mapStateToProps)(Logout));