import {httpClient} from "../utils";

export const widgetService ={
    getJson,
    updateJson
}

function getJson(){
    return httpClient.callApi("GET",`/admin/config/widget/json`);
}

function updateJson(payload){
    return httpClient.callApi("POST",`/admin/config/widget/json`, payload);
}