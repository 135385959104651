import {httpClient} from "../utils";

export const adminService ={
    makemeadmin,
    getPlans,
    getTransactionDetail,
    getSubscriptionInfo,
    changePlan
};

function makemeadmin(payload){    
      return httpClient.callApi("POST", "/admin/make_me_admin", payload);
}

function getPlans(){
    return httpClient.callApi("GET", "/admin/plans")
}

function getTransactionDetail(sessionId){
    return httpClient.callApi("GET", `/admin/stripe_subscription_session/${sessionId}`);
}

function getSubscriptionInfo(){
    return httpClient.callApi("GET", `/admin/stripe_subscription_details`)
}

function changePlan(payload){
    return httpClient.callApi("POST", "/admin/change_plan", payload);
}