import React from "react";
import "./index.css";
function Toggle(props) {
  const {
    text,
    size = "default",
    checked,
    disabled,
    onChange,
    name,
    offstyle = "btn-danger",
    onstyle = "btn-success"
  } = props;

  let displayStyle = checked ? onstyle : offstyle;
  return (
    <>
      <label>
        <span className={`${size} switch-wrapper`}>
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            name={name}
            onChange={e => onChange(e)}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
       {displayStyle===onstyle&& <span className="switch-label" >{text}</span>}
       {displayStyle===offstyle&& <span className="switch-label1">{text}</span>}
      </label>
    </>
  );
}

export default Toggle;