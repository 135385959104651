import {createSlice} from '@reduxjs/toolkit';
import {csvServices} from "../services";
import {csvConstants} from "../constants"

export const initialState ={
    status:"",
    csv:[]
}


export const csvSlice=createSlice({
    name:'csv',
    initialState ,
    reducers:{
        getMails: state => {
            state.status=csvConstants.GET_REQUEST
        },
        getMailsSuccess: (state,{payload}) =>{
            state.csv = payload
            state.status=csvConstants.GET_SUCCESS
        },
        getMailsFailure: (state,{payload}) =>{
            state.csv =payload
            state.status=csvConstants.GET_ERROR
        },
    },
 })

export const {getMails,getMailsSuccess,getMailsFailure} =csvSlice.actions
export const csvSelector = state => state.csv
const csv = csvSlice.reducer;
export default csv;

export function fetchget(){
    return dispatch =>{
        dispatch(getMails());
        csvServices.getMail().then(
            data=>{dispatch(getMailsSuccess(data))},
             error =>{ dispatch(getMailsFailure(error))}
        )
    }
}