import {httpClient} from "../utils";

export const QuestionService ={
    addQuestion,
    getAllQuestion,
    editQuestion,
    deleteQuestion
} 

function addQuestion(payload){
    return httpClient.callApi("POST",`/admin/configure/questions`,payload)
}

function getAllQuestion(formid){
    return httpClient.callApi("GET",`/admin/configure/questions/${formid}`)
}

function editQuestion(payload){
    return httpClient.callApi("PUT",`/admin/configure/questions`,payload)
}

function deleteQuestion(id){
    return httpClient.callApi("DELETE",`/admin/configure/question`)
}