import React, { Component } from 'react';
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import '../../index.css';
// import 'react-phone-number-input/style.css'
// import PhoneInput,{ isValid, parsePhoneNumber, getCountryCallingCode } from 'react-phone-input-2';
// import startsWith from 'lodash.startswith';
// import 'react-phone-input-2/lib/style.css'

import PhoneInput, { isValidPhoneNumber,parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';

import { authenticationActions } from '../../actions';
import notyf from "../../utils/notification.js";
import { BlockPicker } from 'react-color';

class Signup extends Component {  
    constructor(props){
       super(props);

       this.state = {
         phone_number: "",
         name:"",
         otp:"",
         country_code:"",
         mail:"",
         error: false, 
         flow: "details"
       }

      //  if(cookies.get('access_token'))
      // {  window.location.href = '/Inbox';  }

       this.componentDidMount = this.componentDidMount.bind(this);
       this.onChange = this.onChange.bind(this);
       this.onSubmit = this.onSubmit.bind(this);
       this.isAvailable = this.isAvailable.bind(this);

     }

     componentDidMount() {
      //  const script = document.createElement("script");
      //  script.src = "https://spiceblue.co/hellomail-new/support/hide.js";
      //  script.async = true;
      //  document.body.appendChild(script);
     }

     isAvailable = (e) => {
            e.preventDefault();
            const { dispatch } = this.props;
            const { phone_number, mail } = this.state;
            if(isValidPhoneNumber(phone_number)){
               if(getCountryCallingCode(parsePhoneNumber(phone_number).country) == "91"){
                  let payload ={ phone_number };
                  dispatch(authenticationActions.isAvailable(payload));
               }else{
                  if(mail !== ""){
                     let payload = { mail_id: mail};
                     dispatch(authenticationActions.isAvailable(payload));  
                  }else {
                     notyf.open({type: 'error', message: "Enter valid Email Id"});      
                  }
               }
            }else {
               notyf.open({type: 'error', message: "Enter valid Phone number with country code."});
            }
            
      }

      forOtp = () => {
            const { dispatch } = this.props;
            const { phone_number, mail } = this.state;
            if(isValidPhoneNumber(phone_number)){
               if(getCountryCallingCode(parsePhoneNumber(phone_number).country) == "91"){
                  let payload ={ phone_number };
                  dispatch(authenticationActions.sendOtp(payload));
               }else{
                  if(mail !== ""){
                     let payload = { mail };
                     dispatch(authenticationActions.sendOtp(payload));
                  }else {
                     notyf.open({type: 'error', message: "Enter valid Email Id"});      
                  }
               }
            }else {
               notyf.open({type: 'error', message: "Enter valid Phone number with country code."});
            }
      }


      onChange(e){
         this.setState({[e.target.name]:e.target.value});
      }


     onSubmit(e) {
        const { dispatch } = this.props;
        e.preventDefault();
        if(this.state.otp !== "" && this.state.mail !== ""){
            let payload = {
               phone_number: this.state.phone_number,
               name: this.state.name,
               otp: this.state.otp,
               country_code: "+"+getCountryCallingCode(parsePhoneNumber(this.state.phone_number).country),
               mail: this.state.mail,
            }

            dispatch(authenticationActions.signup(payload));
         }else{
            let message = this.state.otp === "" ? "Enter valid OTP." : "Enter Valid Email Id"; 
            notyf.open({type: 'error', message});
         }

     }

     renderText = () => {
         const { authentication } = this.props;
         if(authentication.status === "ISAVAILABLE_REQUEST") return "CHECKING....";
         else if(authentication.status === "SENDINGOTP_REQUEST") return "SENDING OTP...";
         else if(authentication.status === "SIGNUP_REQUEST") return "VERIFING....";
         else return "SIGNUP";
      }

     componentWillReceiveProps(nextProps){
         const prevProps = this.props ;
         const { history } = this.props; 
         let hasPropsChanged = prevProps.authentication.status !== nextProps.authentication.status;

         if(hasPropsChanged){
         if(nextProps.authentication.status === "SENDINGOTP_SUCCESS"){
            this.setState({ flow: "otp" });
            notyf.open({type:'success', message:"OTP sent to your Mobile Number/Email"});
         }
         if(nextProps.authentication.status === "ISAVAILABLE_SUCCESS"){
            if(!nextProps.authentication.available["is_available"]){ 
               notyf.open({type:'error', message:"Mobile Number/Email ID Already Exist. Try Login."});
            }
            else this.forOtp();
         }
         if(nextProps.authentication.status === "SIGNUP_SUCCESS"){
            history.push("/pricing");
         }
         if(nextProps.authentication.status.indexOf("ERROR") != -1)
            notyf.open({type: 'error', message: nextProps.authentication.error})

         }
      }


    render() {
       const { error, flow } = this.state;
     return (
        <div>
        <div>
        <div id="SignUpPage">
           <div >
              <div style={{paddingTop:100}}>
                  Sign up
              </div>

               {/* <div class="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                  <div class="wistia_responsive_wrapper" style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}>
                     <div class="wistia_embed wistia_async_3i2tolwb5r videoFoam=true" style={{ height: "100%", position: "relative", width: "100%" }}><div class="wistia_swatch" style={{ height: "100%", left: 0, opacity: 0, overflow: "hidden", position: "absolute", top: 0, transition: "opacity 200ms", width: "100%" }}><img src="https://fast.wistia.com/embed/medias/3i2tolwb5r/swatch" style={{ filter: "blur(5px)", height: "100%", objectFit: "contain", width: "100%" }} alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div> */}
                    <form method="post" action="#">
                       <div> <input name="name" type="text" placeholder="Your Name" required="" value={this.state.name} onChange={this.onChange} /></div>
                       <div><input name="mail" type="email" placeholder="Email" value="" autocomplete="email" required value={this.state.mail} onChange={this.onChange} /></div>
                       <PhoneInput
                          name="phone_number"
                          placeholder="Phone Number"
                          value={this.state.phone_number}
                          onChange={phone_number => this.setState({ phone_number: phone_number !== "undefined" ? phone_number : "" })}
                       />

                       {flow !== "details" &&
                          <div className="FormControlRow">
                             <div class="FormControlRow__col Login__formRow">
                                <input class="FormControl js-field" id="otp" name="otp" type="number" minlength={4} maxLength={8} placeholder="One Time password" required value={this.state.otp} onChange={this.onChange} />
                             </div>
                          </div>
                       }

                       <div class="Form__communications">
                          <span class="Form__tos">
                             By signing up, you agree to our
                     <a href="https://discover.moonproduct.in/privacy" target="_blank" rel="noopener" style={{color:"grey"}}>{" "}Terms of Service{" "}</a>
                     and
                     <a href="https://discover.moonproduct.in/terms" target="_blank" rel="noopener" style={{color:"grey"}} >{" "}Privacy Notice.</a>
                          </span>

                       </div>
                       <input type="hidden" id="geo-country_code" name="country" value="IN" />
                       <input type="hidden" id="geo-region_code" name="state" value="TG" />
                       <input type="hidden" id="geo-city" name="city" value="Hyderabad" />
                       <input type="hidden" id="geo-lat" name="lat" value="17.3846" />
                       <input type="hidden" id="geo-lon" name="lon" value="78.4574" />
                       <input type="hidden" id="utm_campaign" name="utm_campaign" value="" />
                       <input type="hidden" id="utm_content" name="utm_content" value="" />
                       <input type="hidden" id="utm_medium" name="utm_medium" value="" />
                       <input type="hidden" id="utm_source" name="utm_source" value="" />
                       <input type="hidden" id="utm_term" name="utm_term" value="" />

                       <div class="Form__cta">
                          <button id="submit" type="submit" class="Form__ctaButton" onClick={(e) => flow !== "details" ? this.onSubmit(e) : this.isAvailable(e)}>
                             {this.renderText()}
                          </button>
                       </div>
                    </form>
              <div>
                 <span class="Form__altCopy">
                 Already a user?
                 </span>
                  <a class="Form__altLink" href="login" style={{color:"#26d493"}}>{" "}Login here</a>
                 <br/>
              </div>
           </div>
        </div>
     </div>
   </div>
    );
  }
}

function mapStateToProps(state) {
   const { authentication } = state;
 
   return { authentication };
 }
 
 export default withRouter(connect(mapStateToProps)(Signup));