import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import "../home/index.css";
import Home from "../home";
import utils from "../../utils/utils.js";
import {taskstatusActions,taskviewActions, companyActions} from "../../actions";
import notyf from "../../utils/notification";
import "./index.css"
class Taskboard extends Component {
    constructor(props){
        super(props);
        this.state={
            currentlyDisplayedType: 0,
            name: " ",
            collapsed: -1
        }
    }
    renderTask = (status = 0) => {
        const {dispatch}=this.props;
        this.setState({name:" ", currentlyDisplayedType: 0})
        let payload = { show_all: status }
        dispatch(taskviewActions.View(payload))
     }
     componentWillReceiveProps(nextProps){
        const { user,companies, dispatch, admin, history,task} = nextProps;
        let companyPropsChanged = companies.status !== this.props.companies.status;
           if(companyPropsChanged && companies.status === "GET_SUCCESS"){
               if(user.profile.profile.is_admin){
                this.renderTask();
               }
            }
            if(task.status==="TASKVIEW_ERROR" && task.task.active===false){
                 notyf.open({type:"warning",message:task.task.message});
                 utils.paymentRedirection(task.task.stripe_session_id);
            }
       }
     renderAdditionalInfo = (questions) => {
         let question=JSON.stringify(questions);
        if( questions !== "" ) {
            let q = JSON.parse(question);
            let qKeys = Object.keys(q);

            return (
                <div className="tabled">
                    {
                        qKeys.map((key, index) => {
                            return (
                                <table  key={"key"+index}>
                                    <td > {key} </td>
                                    <td>{q[key]}</td>
                                </table>
                            )
                        })
                    }
                </div>
            )
        } else return (
            <div >
                <div>
                    <p >No Questions posted</p>
                </div>
            </div>
        )
    }
   renderdetails=()=>{
       const {task}=this.props;
       let tasks=task.task;
     
     return (
    <div className="mp-task">
    <div className="mptaskstatus">
        <div className="mptasktitle">Posted  </div>
        {typeof tasks!=="undefined" && tasks.map((i,index)=>{ 
            let date = new Date( Date.parse(i.created_at))   
        return(
            <div key={`key-${index}`}>
        <div class="chart" style={{display:"flex",flexDirection:"row"}}>
            <div>
        <p className="taskuser">{i.user_name}</p>
        {typeof i.contact !=="undefined" && <p style={{marginTop:"15px",marginLeft:"0",display:"flex",flexDirection:"row"}}><a href= {"tel:" +i.contact} style={{textDecoration:"none"}}>{i.contact} <i class="material-icons" style={{color:"green",paddingRight:"8px"}}>call</i></a> </p>}
      
         </div>
         <div className="status"  >
        <p >Status</p>
        <p >{i.task_status}</p>
        
        </div>
        <div className="status"  >
        <p>Contact</p>
        <p>{i.contact_phone}</p>
        <p>{i.contact_mail}</p>
        <p>
         <a href= {"mailto:" + i.contact_mail} className="bold" style={{color:"#26d493"}}><i class="material-icons" style={{fontSize:20}} >contact_mail</i></a>&nbsp;
         <a href={"https://wa.me/"+i.contact_phone.replace('+','')} target ="_blank" style={{color:"#26d493"}}><i class="material-icons" style={{fontSize:20}} >forum</i></a>&nbsp;
         <a href= {"tel:" +i.contact_phone} className="bold" style={{color:"#26d493"}}><i class="material-icons" style={{fontSize:20}} >contact_phone</i></a>
        </p>
        </div>
        <div className="status">
        <p style={{cursor:"pointer",color:"#26d493"}} onClick={()=>this.setState({ collapsed: this.state.collapsed === -1 ? i.id: -1})}> {this.state.collapsed !== i.id ? "View More" : "Hide Less"}</p>
        </div>
        <div className="status"  >
        <p >Posted At</p>
        <p >{date.toDateString()},{date.toLocaleTimeString()}</p>
        </div> 
         </div>
        <div key={this.state.id}>
         {this.state.collapsed === i.id && this.renderAdditionalInfo(i.details)}
         </div>
         <hr></hr></div>)})}
         {typeof tasks==="undefined" &&
         <div className="chart">
             <p style={{textAlign:"center"}}>No task posted yet</p>
            </div> 
             }
        </div>
    </div>)
   }



    render(){
        const {task}=this.props;
        return(
            <Home>
             {task.status==="TASKVIEW_SUCCESS" &&
               this.renderdetails()
               }
            </Home>
        )
    }
}

function mapStateToProps(state) {
    const { admin, user, companies,task ,taskstatus} = state;
    
    return { admin, user, companies,task,taskstatus };
  }
  
  export default withRouter(connect(mapStateToProps)(Taskboard));