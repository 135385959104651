import {httpClient} from "../utils";

export const formsService ={
    getForms,
    addNewForms,
    editForm,
    deleteForm
};

function getForms(){    
      return httpClient.callApi("GET", "/admin/configure/form");
}

function addNewForms(payload){
    return httpClient.callApi("POST",`/admin/configure/form`,payload)
}

function editForm(payload){
    return httpClient.callApi("PUT",`/admin/configure/form`,payload)
}

function deleteForm(payload){
    return httpClient.callApi("DELETE",`/admin/configure/form`,payload)
}