import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { userActions, companyActions } from "../../actions";
import Avatar from "../../images/avatar.png";
import $ from "jquery";
import '../../index.css';
import utils from "../../utils/utils.js";

class Home extends Component {
   constructor(props) {
      super(props);
      this.state = {
         name: '',
         email: false,
         datee: new Date(),
         collapse: false
      }

      if (!utils.isLoggedIn()) {
         window.location.href = "/login";
      }

      this.componentDidMount = this.componentDidMount.bind(this);
      this.collapseClick = this.collapseClick.bind(this);
   }

   collapseClick(e) {
      this.setState((prevState) => {
         return { collapse: !prevState.collapse }
      });
      const collapse = this.state.collapse;
      localStorage.setItem("myEl", !collapse)
   }




   componentDidMount() {
      const { dispatch } = this.props;
      dispatch(userActions.profile());
      var valFromLocalStorage = localStorage.getItem("myEl");
      if (valFromLocalStorage == "true") { valFromLocalStorage = true }
      else { valFromLocalStorage = false }
      this.setState({ collapse: valFromLocalStorage }, () => { });



      //   $("#acc-pop-open").click(function(event) {
      //     $("#acc-pop").toggle();
      //     $('#supp-pop').hide();
      //     $('#act-pop').hide();
      //     $('#user-pop').hide();
      //     $("#allcont-pop").hide();
      //     $("#c-pop").hide();
      //     $("#call-pop").hide();
      //     event.stopPropagation();
      //   });
      //   $("#acc-pop").click(function(e){
      //     e.stopPropagation();
      //   });


   }

   componentWillReceiveProps(nextProps) {
      const { user, history, dispatch } = nextProps;
      let userPropsChanged = user.status !== this.props.user.status;
      if (userPropsChanged) {
         if (user.status === "PROFILE_SUCCESS") {
            let profile = user.profile.profile;
            this.setState({ name: profile.name, email: profile.is_admin });
            if (!profile.is_admin) history.push("/pricing");
            else dispatch(companyActions.getCompanies());
         }
      }
   }



   render() {
      let collapseClasses = (this.state.collapse ? ' is-collapsed' : '');
      const { companies, user } = this.props
      return (
         <div id="main">



            <div class="homecontainer">


               <div class="js-toast-manager">
                  <div class="ToastManager_main_f08"></div>
               </div>

               <div class="content-area-wrap">
                  <div class="js-leftNav LeftNav__wrapper">
                     <div className={`LeftNav${collapseClasses}`}>

                        <div class="ProfileDropdown">
                           <button type="button" class="ProfileDropdown__opener" id="acc-pop-open">
                              <div class="ProfileDropdown__info">
                                 <div class="ProfileDropdown__name">
                                    {/* {this.state.name} */}
                                    {typeof user.profile !== "undefined" && user.profile.profile.email_id}</div>
                                 <div class="ProfileDropdown__organization">
                                    {typeof user.profile !== "undefined" && user.profile.profile.phone_number}
                                    {this.state.email ? "(ADMIN)" : "(NON-ADMIN)"}
                                 </div>
                              </div>

                           </button>
                        </div>

                        <nav class="Menu">
                           <ul class="Menu__list">
                              {/* <li class="Menu__item">
                              <span>
                              <Link to="/forms">
                                 <a class="Menu__link allow-reload">
                                    <i class="material-icons" style={{color:"#fff",paddingRight:"8px"}}>list</i>
                                    <span class="Menu__itemText">Forms</span>
                                 </a>
                              </Link>
                              </span>
                           </li> */}
                              <li class="Menu__item" rel="tooltip" data-original-title="Subscription">
                                 <span>
                                    <Link to="/pricing">
                                       <a class="Menu__link allow-reload">
                                          <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>card_membership</i>
                                          <span class="Menu__itemText" style={{ color: "#fff" }}>Subscription</span>
                                          {/* <span class="Menu__inboxCount">1</span> */}
                                       </a>
                                    </Link>
                                 </span>
                              </li>
                              <li class="Menu__item" rel="tooltip" data-original-title="widgets">
                                 <span>
                                    <Link to="/widget">
                                       <a class="Menu__link allow-reload">
                                          <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>widgets</i>
                                          <span class="Menu__itemText" style={{ color: "#fff" }}>Widget</span>
                                          {/* <span class="Menu__inboxCount">1</span> */}
                                       </a>
                                    </Link>
                                 </span>
                              </li>
                              {companies.status === "GET_SUCCESS" && <li class="Menu__item" rel="tooltip" data-original-title="description">
                                 <span>
                                    <Link to="/admin/forms">
                                       <a class="Menu__link allow-reload">
                                          <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>description</i>
                                          <span class="Menu__itemText" style={{ color: "#fff" }}>Form</span>
                                          {/* <span class="Menu__inboxCount">1</span> */}
                                       </a>
                                    </Link>
                                 </span>
                              </li>}
                              {companies.status === "GET_SUCCESS" && <li class="Menu__item" rel="tooltip" data-original-title="DataBoard">
                                 <span>
                                    <Link to="/auth">
                                       <a class="Menu__link allow-reload">
                                          <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>assignment</i>
                                          <span class="Menu__itemText" style={{ color: "#fff" }}>Data Board</span>
                                          {/* <span class="Menu__inboxCount">1</span> */}
                                       </a>
                                    </Link>
                                 </span>
                              </li>}
                              {companies.status === "GET_SUCCESS" && <li class="Menu__item" rel="tooltip" data-original-title="Customer List">
                                 <span>
                                    <Link to="/admin/customer-files">
                                       <a class="Menu__link allow-reload">
                                          <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>folder_shared</i>
                                          <span class="Menu__itemText" style={{ color: "#fff" }}>Customer List</span>
                                          {/* <span class="Menu__inboxCount">1</span> */}
                                       </a>
                                    </Link>
                                 </span>
                              </li>}
                              {companies.status === "GET_SUCCESS" && <li class="Menu__item" rel="tooltip" data-original-title="Blog">
                                 <span>
                                    <Link to="/admin/listblogs">
                                       <a class="Menu__link allow-reload">
                                          <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>bookmark</i>
                                          <span class="Menu__itemText" style={{ color: "#fff" }}>Blog</span>
                                          {/* <span class="Menu__inboxCount">1</span> */}
                                       </a>
                                    </Link>
                                 </span>
                              </li>}
                              {companies.status === "GET_SUCCESS" && <li class="Menu__item" rel="tooltip" data-original-title="FileUpload">
                                 <span>
                                    <Link to="/admin/files">
                                       <a class="Menu__link allow-reload">
                                          <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>cloud_upload</i>
                                          <span class="Menu__itemText" style={{ color: "#fff" }}>File Upload</span>
                                          {/* <span class="Menu__inboxCount">1</span> */}
                                       </a>
                                    </Link>
                                 </span>
                              </li>}
                              <li class="Menu__item" rel="tooltip" data-original-title="FileUpload">
                                 <span>
                                    <Link to="/settings">
                                       <a class="Menu__link allow-reload">
                                          <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>settings</i>
                                          <span class="Menu__itemText" style={{ color: "#fff" }}>Settings</span>
                                          {/* <span class="Menu__inboxCount">1</span> */}
                                       </a>
                                    </Link>
                                 </span>
                              </li>
                              {/* <li class="Menu__item">
                              <span>
                              <Link to="/logout">
                                 <a class="Menu__link allow-reload">
                                    <span class="Menu__icon">
                                       <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
                                          <path fill-rule="evenodd" d="M14.2222222,0 L1.76888889,0 C0.782222222,0 0.00888888889,0.791111111 0.00888888889,1.77777778 L0,14.2222222 C0,15.2 0.782222222,16 1.76888889,16 L14.2222222,16 C15.2,16 16,15.2 16,14.2222222 L16,1.77777778 C16,0.791111111 15.2,0 14.2222222,0 Z M14.2222222,10.6666667 L10.6666667,10.6666667 C10.6666667,12.1422222 9.46666667,13.3333333 8,13.3333333 C6.53333333,13.3333333 5.33333333,12.1422222 5.33333333,10.6666667 L1.76888889,10.6666667 L1.76888889,1.77777778 L14.2222222,1.77777778 L14.2222222,10.6666667 Z"></path>
                                       </svg>
                                    </span>
                                    <span class="Menu__itemText">Logout</span>
                                 </a>
                              </Link>
                              </span>
                           </li> */}

                           </ul>
                        </nav>

                        <ul class="Menu__list Menu__list--bottom">
                           <li class="Menu__item hide">
                              <span>
                                 <a class="Menu__link" href="#" target="_blank" rel="noopener noreferrer">
                                    <span class="Menu__icon">
                                       <svg xmlns="https://www.w3.org/2000/svg" xlink="https://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="currentColor">
                                          <defs>
                                             <path id="life-ring-a" d="M8,0 C3.58170968,0 0,3.58170968 0,8 C0,12.4182903 3.58170968,16 8,16 C12.4182903,16 16,12.4182903 16,8 C16,3.58170968 12.4182903,0 8,0 Z M13.6030968,3.85674194 L11.5579677,5.90187097 C11.2035484,5.30319355 10.6976452,4.79696774 10.098129,4.44203226 L12.1432581,2.39690323 L12.1432587,2.39690372 C12.6989168,2.80918759 13.1908103,3.30108114 13.6030942,3.85673921 L13.6030968,3.85674194 Z M8,11.0967742 C6.28970968,11.0967742 4.90322581,9.71029032 4.90322581,8 C4.90322581,6.28970968 6.28970968,4.90322581 8,4.90322581 C9.71029032,4.90322581 11.0967742,6.28970968 11.0967742,8 C11.0967742,9.71029032 9.71029032,11.0967742 8,11.0967742 Z M3.85674194,2.39690323 L5.90187097,4.44203226 C5.30319355,4.79645161 4.79696774,5.30235484 4.44203226,5.90187097 L2.39690323,3.85674194 L2.39690357,3.85674147 C2.80918099,3.30107373 3.30107454,2.80918018 3.85674228,2.39690276 L3.85674194,2.39690323 Z M2.39690323,12.1432581 L4.44203226,10.098129 C4.79645161,10.6968065 5.30235484,11.2030323 5.90187097,11.5579677 L3.85674194,13.6030968 L3.85674178,13.6030967 C3.30108372,13.1908128 2.80919017,12.6989192 2.3969063,12.1432612 L2.39690323,12.1432581 Z M12.1432581,13.6030968 L10.098129,11.5579677 C10.6968065,11.2035484 11.2030323,10.6976452 11.5579677,10.098129 L13.6030968,12.1432581 C13.1908097,12.6989162 12.6989161,13.1908097 12.143258,13.6030968 Z"></path>
                                          </defs>
                                          <use fill-rule="evenodd" xlinkHref="#life-ring-a"></use>
                                       </svg>
                                    </span>
                                    <span class="Menu__itemText">Support &amp; FAQs</span>
                                    <span class="Menu__externalIcon">
                                       <svg xmlns="https://www.w3.org/2000/svg" xlink="https://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="currentColor">
                                          <defs>
                                             <path id="external-a" d="M15,2.5 L15,13.5 C15,14.3284375 14.3284375,15 13.5,15 L2.5,15 C1.6715625,15 1,14.3284375 1,13.5 L1,2.5 C1,1.6715625 1.6715625,1 2.5,1 L13.5,1 C14.3284375,1 15,1.6715625 15,2.5 Z M13,3.97221875 C13,3.43528125 12.5647188,3 12.0277813,3 L7.89584375,3 C7.493125,3 7.1666875,3.32646875 7.1666875,3.7291875 L7.1666875,4.1949375 C7.1666875,4.6055 7.50553125,4.935125 7.9159375,4.9238125 L9.666,4.8741875 L3.184625,11.309625 C2.9390625,11.5534375 2.938375,11.950375 3.1830625,12.1950625 L3.80490625,12.8169062 C4.04959375,13.0615937 4.44653125,13.0609062 4.69034375,12.8153437 L11.1258125,6.33396875 L11.0761875,8.0840625 C11.064875,8.49446875 11.3945,8.8333125 11.8050625,8.8333125 L12.2708125,8.8333125 C12.6735313,8.8333125 13,8.50684375 13,8.10415625 L13,3.97221875 Z"></path>
                                          </defs>
                                          <use fill-rule="evenodd" xlinkHref="#external-a"></use>
                                       </svg>
                                    </span>
                                 </a>
                              </span>
                           </li>
                           <li class="Menu__item" rel="tooltip" data-original-title="Logout">
                              <span>
                                 <Link to="/logout">
                                    <a class="Menu__link allow-reload">
                                       <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>exit_to_app</i>
                                       <span class="Menu__itemText" style={{ color: "#fff" }}>Logout</span>
                                    </a>
                                 </Link>
                              </span>
                           </li>
                           {/* <li class="Menu__item">
                           <span>
                           <Link to="/settings">
                              <a class="Menu__link allow-reload">
                                 <i class="material-icons" style={{color:"#fff",paddingRight:"8px"}}>settings</i>
                                 <span class="Menu__itemText">Settings</span>
                              </a>
                              </Link>
                           </span>
                        </li> */}
                        </ul>
                        <ul class="Menu__list Menu__list--bottom Menu__collapse">
                           <li class="Menu__item">
                              <a class="Menu__link" onClick={this.collapseClick}>
                                 <span class="Menu__collapseText">Collapse</span>
                                 <span class="Menu__collapseIcon">
                                    <i class="material-icons" style={{ color: "#fff", paddingRight: "8px" }}>swap_horiz</i>
                                 </span>
                              </a>
                           </li>
                        </ul>
                     </div></div>

                  <div class="content-area">
                     <div class="top-shadow">
                        <div class="js-topNav TopNav">


                           <span class="TopNav__mobileMenuOpener js-mobileMenu glyphicons glyphicons-menu-hamburger"></span>
                           <div class="TopNav__navigation">
                              <span class="TopNav__navigationButton">
                                 <svg fill="currentColor" viewBox="0 0 16 16" version="1.1" xmlns="https://www.w3.org/2000/svg" xlink="https://www.w3.org/1999/xlink">
                                    <polygon points="15 7.125 4.34833333 7.125 9.23666667 2.23666667 8 1 1 8 8 15 9.23666667 13.7633333 4.34833333 8.875 15 8.875"></polygon>
                                 </svg>
                              </span>
                              <span class="TopNav__navigationButton TopNav__navigationButton--forward">
                                 <svg fill="currentColor" viewBox="0 0 16 16" version="1.1" xmlns="https://www.w3.org/2000/svg" xlink="https://www.w3.org/1999/xlink">
                                    <polygon points="15 7.125 4.34833333 7.125 9.23666667 2.23666667 8 1 1 8 8 15 9.23666667 13.7633333 4.34833333 8.875 15 8.875"></polygon>
                                 </svg>
                              </span>
                           </div>
                           {/* <div class="SearchBox">
                           <form class="form-search">
                              <div class="search-container">
                                 <span class="glyphicons glyphicons-search" aria-hidden="true"></span>
                                 <button type="reset" class="clear-btn hide" title="Clear Search">
                                    <svg viewBox="0 0 24 24">
                                       <g>
                                          <path fill="currentColor" d="M12.042,2c-5.523,0-10,4.477-10,10s4.477,10,10,10s10-4.477,10-10S17.564,2,12.042,2z M16.749,15.293 l-1.414,1.414l-3.293-3.292l-3.293,3.292l-1.414-1.414L10.627,12L7.334,8.707l1.414-1.413l3.293,3.293l3.293-3.293l1.414,1.413 L13.456,12L16.749,15.293z"></path>
                                       </g>
                                    </svg>
                                 </button>
                                 <textarea type="search" class="search-query txtarea" placeholder="Search" autocorrect="off" spellcheck="false" tabindex="1"></textarea>
                              </div>
                           </form>
                        </div>                      */}



                        </div>
                        <div class="app-top"></div>
                     </div>



                     <div class="app-content js-appContent">



                        {this.props.children}






                     </div>

                  </div>
               </div>

            </div>







            {/* <div class="acc-pop popover bottom popover--open ProfilePopover has-footer" id="acc-pop">
<div class="popover-arrow lft1"></div>
<div class="popover-content popover-content--withSpacing">
<a class="ProfilePopover__link" href="/team">
<span>
<svg xmlns="https://www.w3.org/2000/svg" xlink="https://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="currentColor">
   <defs>
      <path id="users-invite-a" d="M4.79847619,7.00342857 C6.41828571,7.44228571 7.61904762,8.91047619 7.61904762,10.6666667 L7.61904762,12.1904762 C7.61904762,12.6118095 7.27847619,12.952381 6.85714286,12.952381 L6.0952381,12.952381 L6.0952381,15.2380952 C6.0952381,15.6594286 5.75466667,16 5.33333333,16 L2.28571429,16 C1.86438095,16 1.52380952,15.6594286 1.52380952,15.2380952 L1.52380952,12.952381 L0.761904762,12.952381 C0.340571429,12.952381 0,12.6118095 0,12.1904762 L0,10.6666667 C0,8.91047619 1.2007619,7.44228571 2.82057143,7.00342857 C2.05638095,6.63390476 1.52380952,5.85752381 1.52380952,4.95238095 C1.52380952,3.69219048 2.54933333,2.66666667 3.80952381,2.66666667 C5.06971429,2.66666667 6.0952381,3.69219048 6.0952381,4.95238095 C6.0952381,5.8567619 5.56266667,6.63390476 4.79847619,7.00342857 Z M12.0030476,11.7508571 C13.0125714,12.247619 13.7142857,13.2777143 13.7142857,14.4761905 L13.7142857,15.2380952 C13.7142857,15.6594286 13.3737143,16 12.952381,16 L8.38095238,16 C7.95961905,16 7.61904762,15.6594286 7.61904762,15.2380952 L7.61904762,14.4761905 C7.61904762,13.2777143 8.32152381,12.247619 9.33028571,11.7508571 C8.75733333,11.3348571 8.38095238,10.6651429 8.38095238,9.9047619 C8.38095238,8.64457143 9.40647619,7.61904762 10.6666667,7.61904762 C11.9268571,7.61904762 12.952381,8.64457143 12.952381,9.9047619 C12.952381,10.6651429 12.576,11.3348571 12.0030476,11.7508571 Z M16,2.28571429 L16,3.80952381 L13.7142857,3.80952381 L13.7142857,6.0952381 L12.1904762,6.0952381 L12.1904762,3.80952381 L9.9047619,3.80952381 L9.9047619,2.28571429 L12.1904762,2.28571429 L12.1904762,0 L13.7142857,0 L13.7142857,2.28571429 L16,2.28571429 Z"></path>
   </defs>
   <use fill-rule="evenodd" xlinkHref="#users-invite-a"></use>
</svg>
</span>
Invite People
</a>

<a class="ProfilePopover__link" href="/logout">
<span>
<svg xmlns="https://www.w3.org/2000/svg" xlink="https://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="currentColor">
   <defs>
      <path id="logout-a" d="M12.4,0 L3.6,0 C2.7176,0 2,0.7184 2,1.6 L2,7.2 L8.072,7.2 L6.2344,5.3664 L7.3648,4.2336 L11.1312,7.9928 L7.3664,11.7648 L6.2336,10.6352 L8.0656,8.8 L2,8.8 L2,14.4 C2,15.2824 2.7176,16 3.6,16 L12.4,16 C13.2824,16 14,15.2824 14,14.4 L14,1.6 C14,0.7184 13.2824,0 12.4,0 Z"></path>
   </defs>
   <use fill-rule="evenodd" xlinkHref="#logout-a"></use>
</svg>
</span>
Logout
</a>


</div>
</div> */}
         </div>

      );
   }
}

function mapStateToProps(state) {
   const { authentication, user, companies } = state;
   return { authentication, user, companies };
}

export default withRouter(connect(mapStateToProps)(Home));