export const formsConstants ={
    GET_REQUEST:"GET_ALL_FORMS_REQUEST",
    GET_SUCCESS:"GET_ALL_FORMS_SUCCESS",
    GET_ERROR:"GET_ALL_FORMS_ERROR",

    ADD_REQUEST:"ADDING_FORMS-REQUEST",
    ADD_SUCCESS:"ADDING_FORMS_SUCCESS",
    ADD_ERROR:"ADDING_FORMS_ERROR",

    EDIT_REQUEST:"EDITING_FORMS_REQUEST",
    EDIT_SUCCESS:"EDITING_FORMS_SUCCESS",
    EDIT_ERROR:"EDITING_FORMS_ERROR",

    DELETE_REQUEST:"DELETE_FORMS_REQUEST",
    DELETE_SUCCESS:"DELETE_FORMS_SUCCESS",
    DELETE_ERROR:"DELETE_FORMS_ERROR"
}