import React, { Component } from "react";
import "../../screens/home/index.css";

class TextInput extends Component {
    render () {
        const { 
                onChange = () => null, value="", type = "text", name="" } = this.props;
        
        return(
            <label class="Login__formRow mgt2">
                <input 
                    type={type}
                    name={name} 
                    required  
                    value={value} 
                    onChange={onChange} 
                />
            </label>
        );
    }
}

export default TextInput;