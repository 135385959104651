import React,{Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import utils from "../../utils/utils.js";
import Home from "../home";
import { TopBarLoader } from '../../component';
import { imageActions } from '../../actions/index.js';
import "./index.css"
import notyf from '../../utils/notification.js';
import FileTable from '../../component/filetable/index.js';

class Files extends Component {
    constructor(props){
        super(props);


        if(!utils.isLoggedIn()){
            window.location.href = "/login";
        }
        
    }
    componentWillReceiveProps(nxtProps){
        const {companies,image,dispatch}=nxtProps;
      
        let companyPropsChanged = companies.status !== this.props.companies.status;
      
           if(companyPropsChanged && companies.status === "GET_SUCCESS")
           {
            dispatch(imageActions.getallImage());
             
         }
         if(nxtProps.image.status==="UPLOADING_IMAGE_SUCCESS"){
             notyf.open({type:"success",message:"Your File is upload successfully"})
             dispatch(imageActions.getallImage());
         }
         if(nxtProps.image.status==="DELETE_IMAGE_SUCCESS"){
            notyf.open({type:"success",message:image.images.message})
            dispatch(imageActions.getallImage());
        }
        if(nxtProps.image.status==="UPLOADING_IMAGE_ERROR"||nxtProps.image.status==="DELETE_IMAGE_ERROR"||nxtProps.image.status==="ALL_IMAGE_ERROR"){
            notyf.open({type:"error",message:image.images.message})
        }
       
      }

      handleImage=(event)=> {
        const {dispatch } =this.props;
        let data = document.querySelector("input[type=file]").files;
        for (let i = 0; i < data.length; i++) {
          let file =data[i];
          if (file) {
            dispatch(imageActions.UploadImage(file))
          }
        }
    }


    render(){
        const {image,dispatch} =this.props;
        return(
            <Home>
                <div>
                {(image.status==="ALL_IMAGE_REQUEST"||image.status==="UPLOADING_IMAGE_REQUEST") && <TopBarLoader/>}
                <h1>Files</h1>
                <div className="upload-btn-wrapper">
                   <button>
                       <i className="material-icons" style={{verticalAlign:"top"}}>cloud_upload</i>
                          Upload a file
                    </button>
                   <input id="imageUpload"  onChange={this.handleImage} type="file"accept="image/png, image/gif, image/jpeg"enctype="multipart/form-data" name="fileupload" />
                </div>
                 <div>
                     {image.status==="ALL_IMAGE_SUCCESS"
                     && <FileTable content={image.images.output} dispatch={dispatch}/>}
                 </div>
                </div>
            </Home>
        )
    }
  
}

function mapStateToProps(state){
    const {admin,user,companies,image}=state;
    return {admin,user,companies,image}
}

export default withRouter(connect(mapStateToProps)(Files))