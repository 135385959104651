import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter,Route,Switch} from "react-router-dom";
import {persistStore} from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import Home from "./screens/home";
import BrokenPage from "./screens/broken_page";
import Pricing from "./screens/pricing";
import RootLoader from "./screens/root_loader";
import LoginCredentials from "./screens/login/credentials.js";
import LoginWithEmail from "./screens/login/emailCredentials.js";
import Login from "./screens/login";
import Settings from "./screens/settings";
import Signup from "./screens/signup";
import Logout from "./screens/logout";
import Forms from "./screens/forms";
import PaymentStatus from "./screens/paymentstatus";
import WidgetConfig from "./screens/widget";
import Taskboard from "./screens/taskboard/Taskboard";
import Companiesloader from "./screens/taskboard";
import ListBlog from "./screens/blog";
import CreateNewBlog from "./screens/blog/createnew";
import EditBlog from "./screens/blog/editblog";
import Files from "./screens/files";
import Question from "./screens/questions";
import Customer from "./screens/customer";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = ({store}) => {

  return(
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)} >
      <BrowserRouter>
        <Switch> 
          <Route exact path="/" component={RootLoader} />
          <Route exact path="/logincredentials" component={LoginCredentials}/>
          <Route exact path="/loginWithEmail" component={LoginWithEmail}/>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/pricing" component={Pricing}/>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/forms" component={Forms} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/paymentstatus" component={PaymentStatus}/>
          <Route exact path="/widget" component={WidgetConfig}/>
          <Route exact path="/taskboard" component={Taskboard}/>
          <Route exact path="/auth" component={Companiesloader}/>
          <Route exact path="/admin/listblogs" component={ListBlog}/>
          <Route exact path="/admin/createnewblog" component={CreateNewBlog}/>
          <Route exact path={"/admin/editblog/:url"} component={EditBlog}/>
          <Route exact path="/admin/files" component={Files}/>
          <Route exact path="/admin/forms" component={Forms}/>
          <Route exact path={"/admin/question/:url/:id"} component={Question}/>
          <Route exact path="/admin/customer-files" component={Customer}/>
          <Route component={BrokenPage} />
        </Switch>
      </BrowserRouter>
    </PersistGate>
    </Provider>
  )
}

export default App;