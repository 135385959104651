import {createSlice} from '@reduxjs/toolkit';
import {CustomerServices} from "../services";
import {CustomerConstants} from "../constants"

export const initialState ={
    status:"",
    customer:[]
}


export const customerSlice=createSlice({
    name:'customer',
    initialState ,
    reducers:{
        getCustomerlists: state => {
            state.status=CustomerConstants.GET_REQUEST
        },
        getCustomerlistsSuccess: (state,{payload}) =>{
            state.customer = payload
            state.status=CustomerConstants.GET_SUCCESS
        },
        getCustomerlistsFailure: (state,{payload}) =>{
            state.customer =payload
            state.status=CustomerConstants.GET_ERROR
        },
    },
 })

export const {getCustomerlists,getCustomerlistsSuccess,getCustomerlistsFailure} =customerSlice.actions

export const customerSelector = state => state.customer /**This For React Hooks Usage While USing that use this on Your Screen */

const customer = customerSlice.reducer;
export default customer;


export function getCustomer(){
    return dispatch =>{
        dispatch(getCustomerlists());
        CustomerServices.getCustomers().then(
            data=>{dispatch(getCustomerlistsSuccess(data))},
             error =>{ dispatch(getCustomerlistsFailure(error))}
        )
    }
}