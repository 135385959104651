import { adminConstants } from "../constants";

const initialState = {
    status: "",
    admin: [],
    subscription: [],
    transaction: {},
    error: {}
};

export function admin(state =initialState,action){
    switch(action.type){
        case adminConstants.ADMIN_REQUEST:
            return{
                ...state,
                status:"ADMIN_REQUEST"
            };
        case adminConstants.ADMIN_SUCCESS:
            return {
                ...state,
                status: "ADMIN_SUCCESS",
                admin: action.admin
            };
        case adminConstants.ADMIN_ERROR:
            return {
                ...state,
                status: "ADMIN_ERROR",
                error: action.error
            };
        case adminConstants.GET_SUB_REQUEST:
            return{
                ...state,
                status:"GET_SUB_REQUEST"
            };
        case adminConstants.GET_SUB_SUCCESS:
            return {
                ...state,
                status: "GET_SUB_SUCCESS",
                subscription: action.admin
            };
        case adminConstants.GET_SUB_ERROR:
            return {
                ...state,
                status: "GET_SUB_ERROR",
                error: action.error
            };
        case adminConstants.GET_TRANS_REQUEST:
            return{
                ...state,
                status:"GET_TRANS_REQUEST"
            };
        case adminConstants.GET_TRANS_SUCCESS:
            return {
                ...state,
                status: "GET_TRANS_SUCCESS",
                transaction: action.transaction
            };
        case adminConstants.GET_TRANS_ERROR:
            return {
                ...state,
                status: "GET_TRANS_ERROR",
                error: action.error
            };
        case adminConstants.SUB_INFO_REQUEST:
            return{
                ...state,
                status:"SUB_INFO_REQUEST"
            };
        case adminConstants.SUB_INFO_SUCCESS:
            return {
                ...state,
                status: "SUB_INFO_SUCCESS",
                transaction: action.info
            };
        case adminConstants.SUB_INFO_ERROR:
            return {
                ...state,
                status: "SUB_INFO_ERROR",
                error: action.error
            };
        case adminConstants.CHANGE_SUB_REQUEST:
            return{
                ...state,
                status:"CHANGE_SUB_REQUEST"
            };
        case adminConstants.CHANGE_SUB_SUCCESS:
            return {
                ...state,
                status: "CHANGE_SUB_SUCCESS",
                transaction: action.admin
            };
        case adminConstants.CHANGE_SUB_ERROR:
            return {
                ...state,
                status: "CHANGE_SUB_ERROR",
                error: action.error
            };
        default:
            return state;
    }
}