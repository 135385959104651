import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import '../../index.css';
import utils from "../../utils/utils.js";
import { adminActions } from '../../actions';
import Home from "../home";
import IframeCode from "./iframecode";
import FormConfig from "./formconfig";
import notyf from '../../utils/notification';
import Widgetform from "./widgetform";


class Widget extends Component {  
    constructor(props){
       super(props);
       this.state = {
           error: false
        }

        if(!utils.isLoggedIn()){
            window.location.href = "/login";
        }

    }

     

     componentDidMount() {
        const { dispatch } = this.props;

        // dispatch(adminActions.getSubscriptionInfo());
        
     }

     componentWillReceiveProps(nextProps){
         const { user,companies, dispatch, admin, history } = nextProps;
         let companyPropsChanged = companies.status !== this.props.companies.status;
         let adminPropsChanged = admin.status !== this.props.admin.status;
         let userPropChanged = user.status !== this.props.user.status;

        // if(companyPropsChanged && companies.status === "GET_SUCCESS"){
        //     let profile = user.profile.profile;
        //     if(!profile.is_admin){
        //         this.setState({ error : true});
        //         history.push("/pricing");
        //     }
        //     else if(profile.is_admin) 
        //         dispatch(adminActions.getSubscriptionInfo());
        // }
        if(user.status === "PROFILE_SUCCESS" && userPropChanged){
            if(!user.profile.profile.is_admin){
                this.setState({ error : true});
                history.push("/pricing");
            }
        }
        if(user.status === "PROFILE_SUCCESS"){
            if(companyPropsChanged && companies.status === "GET_SUCCESS"){
                if(user.profile.profile.is_admin){
                    dispatch(adminActions.getSubscriptionInfo())
                }
            }
        }
        if(adminPropsChanged && admin.status === "SUB_INFO_ERROR" && admin.error.code === 500){
            notyf.open({type: "error", message: "Something Went Wrong. Try again later."})
        }

     }

    

    render() {
        const { admin } =this.props;
        
        return (
            <Home>
                {
                    admin.status === "SUB_INFO_SUCCESS"  &&
                    <div> 
                        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}>
                            <div style={{textAlign: "center", marginBottom: 20}}>
                                <p style={{fontSize: 20}}>You can now copy the widget iframe code and link with your website at the ease...</p>
                            </div>
                            <IframeCode />
                            <Widgetform />
                        </div>
                    </div>
                }
                {   admin.status === "SUB_INFO_ERROR" && !admin.error.active && admin.error.code !== 500 &&
                    <div>
                        <div style={{backgroundColor: "#e44", height: "100px", borderRadius: "50px", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "row"}}>
                                <i class="material-icons" style={{color:"#fff",fontSize: 40, padding:"0px 8px"}}>query_builder</i>
                                <p style={{color: "#fff", fontSize: 20}}>Payment Pending</p>
                        </div>
                        <div class="FullPageMessage-Message center">
                            <h2 class="mb2 Text Text-color--gray400 Text-fontSize--16 Text-fontWeight--500">
                                <span>Payment not received yet</span>
                            </h2>
                            <p class="mb2 FullPageMessage-Message-Detail Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                                <span>{admin.error.message}</span>
                            </p>
                            <button onClick={() => utils.paymentRedirection(admin.error.stripe_session_id)}>
                                {"Pay"}
                            </button>

                        </div>
                    </div>
                }
                {
                    (this.state.error) &&
                    <div class="FullPageMessage-Message center">
                        <h2 class="mb2 Text Text-color--gray400 Text-fontSize--16 Text-fontWeight--500">
                            <span>Your not a Admin</span>
                        </h2>
                        <p class="mb2 FullPageMessage-Message-Detail Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                            <span>Please subscribe a plan to enjoy the service</span>
                        </p>
                        <p class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                            <a class="Link Link--primary" href="/pricing" target="_self"><span>See plans</span></a>
                        </p>
                    </div>
                }
            </Home>
                  
        );
    }
}

function mapStateToProps(state) {
  const { companies, admin, user } = state;

  return { admin, companies, user };
}

export default withRouter(connect(mapStateToProps)(Widget));