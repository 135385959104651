import {httpClient} from "../utils";

export const settingsService ={
    getSmsEmailNotification,
    putSmsEmailNotification
};

function putSmsEmailNotification(payload){    
      return httpClient.callApi("PUT", "/admin/configure/set_sms_mail", payload);
}

function getSmsEmailNotification(){
    return httpClient.callApi("GET", "/admin/configure/set_sms_mail")
}