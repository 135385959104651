import {  adminConstants } from "../constants";
import {  adminService   } from "../services";

export const adminActions ={
    makemeadmin,
    getPlans,
    getTransactionDetail,
    getSubscriptionInfo,
    changePlan
};


function makemeadmin(payload){
    return dispatch =>{
        dispatch(request());
        adminService.makemeadmin(payload).then(
            admin =>{ dispatch(success(admin)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return {type:adminConstants.ADMIN_REQUEST};
    }
    function success(admin){
        return {type:adminConstants.ADMIN_SUCCESS,admin};
    }
    function failure(error){
        return {type:adminConstants.ADMIN_ERROR,error};
    }
}

function getPlans(){
    return dispatch =>{
        dispatch(request());
        adminService.getPlans().then(
            admin =>{ dispatch(success(admin)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type:adminConstants.GET_SUB_REQUEST };
    }
    function success(admin){
        return { type:adminConstants.GET_SUB_SUCCESS, admin};
    }
    function failure(error){
        return { type:adminConstants.GET_SUB_ERROR, error };
    }
}

function getTransactionDetail(sessionId){
    return dispatch =>{
        dispatch(request());
        adminService.getTransactionDetail(sessionId).then(
            admin =>{ dispatch(success(admin)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type:adminConstants.GET_TRANS_REQUEST };
    }
    function success(transaction){
        return { type:adminConstants.GET_TRANS_SUCCESS, transaction};
    }
    function failure(error){
        return { type:adminConstants.GET_TRANS_ERROR, error };
    }
}

function getSubscriptionInfo(){
    return dispatch =>{
        dispatch(request());
        adminService.getSubscriptionInfo().then(
            admin =>{ dispatch(success(admin)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type:adminConstants.SUB_INFO_REQUEST };
    }
    function success(info){
        return { type:adminConstants.SUB_INFO_SUCCESS, info};
    }
    function failure(error){
        return { type:adminConstants.SUB_INFO_ERROR, error };
    }
}

function changePlan(payload){
    return dispatch =>{
        dispatch(request());
        adminService.changePlan(payload).then(
            admin =>{ dispatch(success(admin)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return { type:adminConstants.CHANGE_SUB_REQUEST };
    }
    function success(admin){
        return { type:adminConstants.CHANGE_SUB_SUCCESS, admin};
    }
    function failure(error){
        return { type:adminConstants.CHANGE_SUB_ERROR, error };
    }
}