import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import '../../index.css';
import { formsActions, companyActions } from '../../actions';



class IframeCode extends Component {  
    constructor(props){
       super(props);
       this.state = {
           error: false
        }

    }
     componentDidMount() {
        const { dispatch, companies } = this.props;

        if(companies.status !== "GET_SUCCESS")
            dispatch(companyActions.getCompanies());
        dispatch(formsActions.getForms());
        
     }

     componentWillReceiveProps(nextProps){
         const { forms, companies, dispatch } = nextProps;
         let companyPropsChanged = companies.status !== this.props.companies.status;
         let formsPropsChanged = forms.status !== this.props.forms.status;

         if(companyPropsChanged){
            if(companies.status === "GET_ERROR")
                this.setState({ error: true });
            else if(companies.status === "GET_SUCCESS")
                dispatch(formsActions.getForms());
         }
         if(formsPropsChanged){
            if(forms.status === "GET_ERROR")
                this.setState({error: true });
            else if(forms.status === "GET_SUCCESS")
                this.setState({error: false});
            
         }

     }

    

    render() {
        const { forms, companies } =this.props;
        let source = "";
        if(forms.status === "GET_SUCCESS" && companies.status === "GET_SUCCESS")
            source = `https://development.moonproduct.in?id=${companies.list.message[0].client_id}&form=${forms.list.message[0].id}`;

        return (
            <div>
                { forms.status === "GET_SUCCESS" && companies.status === "GET_SUCCESS" && 
                <code>
                    {` <iframe src="${source}" frameBorder=0 width="100%" height="100%"> </iframe> `}
                </code>
                }
                {
                    (forms.status === "GET_REQUEST" || companies.status === "GET_REQUEST") && 
                    <code>
                        <div style={{textAlign: "center", justifyContent: "center"}}>
                            Your widget iframe is loading ...
                        </div>
                    </code>
                }
                {
                    this.state.error && 
                    <code>
                        <div style={{textAlign: "center", justifyContent: "center"}}>
                            Sry!! something went wrong.
                        </div>
                    </code>
                }
            </div>  
        );
    }
}

function mapStateToProps(state) {
  const { companies, forms } = state;

  return { companies, forms };
}

export default withRouter(connect(mapStateToProps)(IframeCode));