import {companyConstants} from "../constants";
const initialState ={
    status: ""
};

export function companies(state =initialState,action){
    switch(action.type){
        case companyConstants.GET_REQUEST:
            return{
                ...state,
                status:"GET_REQUEST"
            };
        case companyConstants.GET_SUCCESS:
            return {
                ...state,
                status: "GET_SUCCESS",
                list: action.res
            };
        case companyConstants.GET_ERROR:
            return {
                ...state,
                status: "GET_ERROR",
                error: action.err
            };
        default:
            return state;
    }
}