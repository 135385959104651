import {imageConstants} from "../constants";
import {imageService} from "../services";

export const imageActions ={
    UploadImage,
    getallImage,
    deleteImage
}

function UploadImage(payload){
    return dispatch=>{
        dispatch(request());
        imageService.uploadimage(payload).then(
            message => dispatch(success(message)),
            error   => dispatch(failure(error))
        );
    };

    function request(){
        return {type:imageConstants.IMAGE_REQUEST};
    }
    function success(message){
        return {type:imageConstants.IMAGE_SUCCESS,message};
    }
    function failure(error){
        return {type:imageConstants.IMAGE_ERROR,error}
    }

}

function getallImage(){
    return dispatch=>{
        dispatch(request());
        imageService.getallimage().then(
            message => dispatch(success(message)),
            error => dispatch(failure(error))
        );
    }
    function request(){
        return {type:imageConstants.ALL_REQUEST};
    }
    function success(message){
        return {type:imageConstants.ALL_SUCCESS,message};
    }
    function failure(error){
        return {type:imageConstants.ALL_ERROR,error}
    }
}

function deleteImage(imagename){
    return dispatch =>{
        dispatch(request());
        imageService.deleteimage(imagename).then(
            message => dispatch(success(message)), error => dispatch(failure(error))
        );
    };
    function request(){
        return {type:imageConstants.DELETE_REQUEST}
    }
    function success(message){
        return {type:imageConstants.DELETE_SUCCESS,message}
    }
    function failure(error){
        return {type:imageConstants.DELETE_ERROR,error}
    }
}