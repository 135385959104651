import {widgetConstants} from "../constants";
import {widgetService} from "../services";

export const widgetActions ={
    getJson,
    updateJson
}

function getJson(){
    return dispatch =>{
        dispatch(request());
        widgetService.getJson().then(
            res => dispatch(success(res)),error => dispatch(failure(error))
        );
    };
    function request(){
        return {type:widgetConstants.GET_JSON_REQUEST};
    }
    function success(res){
        return {type:widgetConstants.GET_JSON_SUCCESS,res};
    }
    function failure(error){
        return {type:widgetConstants.GET_JSON_ERROR,error}
    }
}

function updateJson(payload){
    return dispatch =>{
        dispatch(request());
        widgetService.updateJson(payload).then(
            res => dispatch(success(res)),
            error => dispatch(failure(error))
        );
    };
    function request(){
        return {type:widgetConstants.UPDATE_JSON_REQUEST};
    }
    function success(res){
        return {type:widgetConstants.UPDATE_JSON_SUCCESS,res};
    }
    function failure(error){
        return {type:widgetConstants.UPDATE_JSON_ERROR,error}
    }
}