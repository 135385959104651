import {httpClient} from "../utils";

export const blogService ={
    getallblog,
    viewblog,
    postblog,
    updateblog,
    deleteblog
}

function getallblog(page){
    return httpClient.callApi("GET",`/blog/posts/${page}`)
}

function viewblog(url){
    return httpClient.callApi("GET",`/blog/${url}`)
}

function postblog(payload){
    return httpClient.callApi("POST",`/admin/blog`,payload,false,false)
}

function updateblog(payload){
    return httpClient.callApi("PUT",`/admin/blog`,payload,false)
}

function deleteblog(id){
    return httpClient.callApi("DELETE",`/admin/blog/${id}`)
}