export const QuestionConstants ={
    ADD_REQUEST:"ADD_QUESTION_REQUEST",
    ADD_SUCCESS:"ADD_QUESTION_SUCCESS",
    ADD_ERROR:"ADD_QUESTION_ERROR",
    
    GET_REQUEST:"GET_QUESTION_REQUEST",
    GET_SUCCESS:"GET_QUESTION_SUCCESS",
    GET_ERROR:"GET_QUESTION_ERROR",

    EDIT_REQUEST:"EDIT_QUESTION_REQUEST",
    EDIT_SUCCESS:"EDIT_QUESTION_SUCCESS",
    EDIT_ERROR:"EDIT_QUESTION_ERROR",

    DELETE_REQUEST:"DELETE_QUESTION_REQUEST",
    DELETE_SUCCESS:"DELETE_QUESTION_SUCCESS",
    DELETE_ERROR:"DELETE_QUESTION_ERROR",
}