import { userConstants } from "../constants";
const initialState ={
    status: ""
};

export function user(state =initialState,action){
    switch(action.type){
        case userConstants.PROFILE_REQUEST:
            return{
                ...state,
                status:"PROFILE_REQUEST"
            };
        case userConstants.PROFILE_SUCCESS:
            return {
                ...state,
                status: "PROFILE_SUCCESS",
                profile: action.user
            };
        case userConstants.PROFILE_ERROR:
            return {
                ...state,
                status: "PROFILE_ERROR",
                error: action.error
            };
        default:
            return state;
    }
}