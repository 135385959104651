import appinfo from "../app.json";
import Store from "../reducers";
import {httpError} from "./httperror";


export const httpClient = {
    deleteReq,
    get,
    getHeaders,
    callApi,
    handleResponse,
    post,
    postImage,
}

function getHeaders(){

    let user = Store.getState().authentication.user;
    let company = Store.getState().companies.list;

    let headers={
        "Accept":"application/json",
        "Content-Type":"application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-device": "WEB",
        // "x-access-client-id": appinfo["x-access-client-id"]
    }

    if(typeof user != "undefined"){
      headers["x-access-token"] = user["token"];
    }

    if(typeof company !=  "undefined"){
      headers["x-access-client-id"] = company.message[0].client_id
    }
    // headers["x-access-client-id"] = "d06d5d138b3621228b48";

    return {headers};
}

function getRequestOption(credentials){
  let headers = getHeaders(credentials).headers;
  let requestOptions ={
    credentials: "include",
    headers
  };

  if(!credentials){
    delete requestOptions["credentials"];
  }

  return requestOptions;
}
function get(credentials = false){
    let requestOptions = getRequestOption(credentials);

    requestOptions["method"] ="GET";
    return requestOptions;
}
function put(payload,credentials=false){
  let requestOptions = getRequestOption(credentials);

  requestOptions["method"] ="PUT";
  requestOptions["body"] =JSON.stringify(payload);
  return requestOptions;
}
function deleteReq(payload,credentials = false) {
    let requestOptions = getRequestOption(credentials);

    requestOptions["method"] ="DELETE";
    requestOptions["body"] =JSON.stringify(payload);
    return requestOptions;

}

function post(payload, credentials = false){
    let requestOptions = getRequestOption(credentials);

    requestOptions["method"] ="POST";
    requestOptions["body"] =JSON.stringify(payload);
    return requestOptions;
}

function postImage(payload){
  let user = Store.getState().authentication.user;
  let company = Store.getState().companies.list;

    let requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          // "Content-Type": "multipart/form-data",
          
        },
        body: payload
      };
      if(typeof user != "undefined"){
        requestOptions.headers["x-access-token"] = user["token"];
      }
  
      if(typeof company !=  "undefined"){
        requestOptions.headers["x-access-client-id"]=company.message[0].client_id
      }
      return requestOptions;
}


function callApi(
    method,
    endpoint,
    payload = {},
    credentials = false,
    image = false
  ) {
    let requestOptions = {};
    let url = appinfo.url;
  
    switch (method) {
      case "GET":
        requestOptions = get(credentials, payload);
        break;
      case "POST":
        if (image) {
          requestOptions = postImage(payload);
        } else {
          requestOptions = post(payload, credentials);
        }
        break;
      case "PUT":
        requestOptions=put(payload,credentials);
        break;
      case "DELETE":
        requestOptions = deleteReq(payload,credentials);
        break;
      default:
        console.log("Nothing here");
    }
    return fetch(`${url}${endpoint}`, requestOptions).then(handleResponse);
  }
  

  function handleResponse(response) {
    let json = response.json();
    if (!(response.status === 200 || response.status === 201)) {
      return json.then(res => {
        return Promise.reject(res);
      });
    }
  
    return json;
  }
  