import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import utils from "../../utils/utils.js";
import Home from "../home";
import { TopBarLoader } from '../../component';
import ReactModal from "react-modal";
import { QuestionActions, QuestionOptionActions } from "../../actions";
import notyf from "../../utils/notification.js";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Form_Id: "",
      question_Id: "",
      prev: "",
      title: "",
      value: "",
      optionamount: "",
      callback: "",
      currency: "",
      online: false,
      parent: "",
      type: "",
      OptionId: "",
      Point: "",
      buttontype: [{ name: "Button", value: "navButton" }, { name: "Text", value: "text" }, { name: "Radio", value: "button" }],
      questionValue: "",
      prevQuestion: null,
      selectedOption: null,
      optionValue: "",
      nextQuestion: null,
    };

    if (!utils.isLoggedIn()) {
      window.location.href = "/login";
    }
    this.onChange = this.onChange.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.addOption = this.addOption.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  showOption = (id) => {
    const { dispatch } = this.props;
    this.setState({ selectedOption: id })
    dispatch(QuestionOptionActions.getOption(id))
  }

  handleOpenModal = (title, id, formid, prev, value, amount, callback, currency, optionid, name, online, parent, type, points) => {
    let typequestion = value;
    //value is consider as 1-question and 0 -option type
    if (typequestion === 1) {
      this.setState({ showModal: true, Name: title, question_Id: id, Form_Id: formid, prev: prev , value: value, title: "Edit your question" });
    }
    if (typequestion === 0) {
      this.setState({ showModal: true, value: value, optionamount: amount, callback: callback, currency: currency, OptionId: optionid, Name: name, online: online, parent: parent, type: type, title: "Edit your options", Point: points })
    }
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  addQuestion() {
    const { dispatch } = this.props;
    let payload = {
      "form_id": this.props.match.params.id,
      "title": this.state.questionValue,
      "prev": this.state.prevQuestion === "Nothing" ? null : this.state.prevQuestion
    }
    dispatch(QuestionActions.addQuestions(payload))
    dispatch(QuestionActions.getAllQuestion(this.props.match.params.id))
    this.setState({
      question: "",
      prevQuestion: null
    });
  }

  addOption() {
    const { dispatch } = this.props;
    let payload = {
      "amount": 0,
      "next": this.state.nextQuestion === "Nothing" ? null : this.state.nextQuestion,
      "currency_type": "INR",
      "name": this.state.optionValue,
      "online_payment": this.state.online,
      "question_id": this.state.selectedOption,
      "type": this.state.type,
      "points": 0
    }
    dispatch(QuestionOptionActions.addOption(payload))
    dispatch(QuestionActions.getAllQuestion(this.props.match.params.id))
  }

  onSubmit = () => {
    const { dispatch } = this.props;
    if (this.state.value === 1) {
      let payload = {
        "form_id": this.state.Form_Id,
        "id": this.state.question_Id,
        "title": this.state.Name,
        "prev": this.state.prev === "Nothing" ? null : this.state.prev
      }
      dispatch(QuestionActions.editQuestions(payload))
      this.handleCloseModal()
    }
    if (this.state.value === 0) {
      let payload = {
        "amount": this.state.optionamount,
        "callback": this.state.callback === "Nothing" ? null : this.state.callback ,
        "currency_type": this.state.currency,
        "id": this.state.OptionId,
        "name": this.state.Name,
        "online_payment": this.state.online,
        "parent": this.state.parent,
        "type": this.state.type,
        "points": 0
      }
      dispatch(QuestionOptionActions.editOption(payload))
      this.handleCloseModal()
    }
  }
  set_option = (event) => {
    this.setState({ type: event.target.value });
  }

  componentWillReceiveProps(nxtProps) {
    const { companies, forms, dispatch, question, option } = nxtProps;
    let id = this.props.match.params.id;

    let companyPropsChanged = companies.status !== this.props.companies.status;

    if (companyPropsChanged && companies.status === "GET_SUCCESS") {
      dispatch(QuestionActions.getAllQuestion(id))
    }
    if (question.status === "EDIT_QUESTION_SUCCESS") {
      notyf.open({ type: "success", message: question.questions.message })
      dispatch(QuestionActions.getAllQuestion(id))
    }
    if (option.status === "EDIT_OPTION_SUCCESS") {
      notyf.open({ type: "success", message: option.options.message })
      dispatch(QuestionOptionActions.getOption(this.state.parent))
    }
    if (option.status === "EDIT_OPTION_ERROR") {
      notyf.open({ type: "error", message: option.options.message })
    }
  }

  render() {
    const { question, option } = this.props;
    return (
      <Home>
        {(question.status === "GET_QUESTION_REQUEST" || question.status === "EDIT_QUESTION_REQUEST" || option.status === "EDIT_OPTION_REQUEST") && <TopBarLoader />}
        <div><h3> Your Widget Question</h3>
          {(question.status === "GET_QUESTION_SUCCESS") &&
            <div>
              {question.questions.message.map((i, index) => {
                return (
                  <div>
                    <div class="input-container" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <input type="text" name="question" value={i.title} />
                      <div style={{ cursor: "pointer", margin: 5 }} onClick={() => this.handleOpenModal(i.title, i.id, i.form_id, i.prev, 1)}>
                        <a><i class="material-icons" style={{ fontSize: 15, color: "blue" }}>edit</i>{""} Edit</a>
                      </div>
                      <button onClick={() => this.showOption(i.id)}>Options</button>

                    </div>
                  </div>
                )
              })}
              {<div style={{backgroundColor:"#FFF",padding:8}}>
                <div>
                  <label>Question <input type="text" name="questionValue" placeholder="Enter Questions" value={this.state.questionValue} onChange={this.onChange} /></label>
                  <label>Pick your previous question
                <select name="prevQuestion" value={this.state.prevQuestion === null ? "Nothing" : this.state.prevQuestion} onChange={this.onChange}>
                      {question.status === "GET_QUESTION_SUCCESS" && question.questions.message.map((i, index) => { return (<option key={"Question" + i.id} value={i.id}>{i.title}</option>) })
                      }
                      <option key={"Question" + 0} value="Nothing">Nothing</option>
                    </select></label>
                </div>
                <div><button onClick={this.addQuestion}>Add a Question</button></div>
              </div>}
              <div>
                {option.status === "GET_OPTION_SUCCESS" && <h3> Your Widget Options</h3>}
                {option.status === "GET_OPTION_SUCCESS" &&
                  option.options.message.map(j => {
                    return (
                      <div id={j} class="input-container" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <input type={j.type} value={j.name} />
                        <div style={{ cursor: "pointer", margin: 5 }} onClick={() => this.handleOpenModal("", "", "", " ", 0, j.amount, j.callback, j.currency_type, j.id, j.name, j.online_payment, j.parent, j.type, j.points)}>
                          <a><i class="material-icons" style={{ fontSize: 15, color: "blue" }}>edit</i>{""} Edit</a>
                        </div>
                      </div>)
                  })}
                {option.status === "GET_OPTION_SUCCESS" && <div >
                  <label>Question Options<input type="text" name="optionValue" placeholder="Enter Question Option" value={this.state.optionValue} onChange={this.onChange} /></label>
                  <label>Pick your next question 
                <select name="nextQuestion" value={this.state.nextQuestion} onChange={this.onChange}>
                      {question.status === "GET_QUESTION_SUCCESS" && question.questions.message.map((i, index) => { return (<option key={"Question" + i.id} value={i.id}>{i.title}</option>) })
                      }
                      <option key={"Question" + 0} value="Nothing">Nothing</option>
                    </select></label>
                    <label>Choose your Option Type
                  <select value={this.state.type} onChange={this.set_option} >
                    {this.state.buttontype.map(i => {
                      return (
                        <option value={i.value}>{i.name}</option>
                      )
                    })}
                  </select></label>
                  <div><button onClick={this.addOption}>Add an Option</button></div>
                </div>}

              </div>
            </div>
          }
        </div>
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          style={customStyles}>
          <h1>{this.state.title}</h1>
          <div class="input-container">
            <input type="text" name="Name" value={this.state.Name} onChange={e => this.onChange(e)} />
          </div>
          {this.state.value === 0 &&
            <div>
              <p>Choose your Option Type</p>
              <select value={this.state.type} onChange={this.set_option} >
                {this.state.buttontype.map(i => {
                  return (
                    <option value={i.value}>{i.name}</option>
                  )
                })}
              </select>
              <label>Pick your next question
                <select name="callback" value={this.state.callback === null ? "Nothing":this.state.callback } onChange={this.onChange}>
                  {question.status === "GET_QUESTION_SUCCESS" && question.questions.message.map((i, index) => { return (<option key={"Question" + i.id} value={i.id}>{i.title}</option>) })
                }
                <option key={"Question" + 0} value="Nothing">Nothing</option>
                </select></label>
            </div>
          }
          {this.state.value === 1 &&
            <label>Pick your previous question
                <select name="prev" value={this.state.prev === null ? "Nothing": this.state.prev} onChange={this.onChange}>
                {question.status === "GET_QUESTION_SUCCESS" && question.questions.message.map((i, index) => { return (<option key={"Question" + i.id} value={i.id}>{i.title}</option>) })
                }
                <option key={"Question" + 0} value="Nothing">Nothing</option>
              </select></label>
          }

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
            <div>
              <button onClick={() => this.handleCloseModal()}>Cancel</button>
            </div>
            <div >
              <button onClick={() => this.onSubmit()}>Save</button>
            </div>
          </div>
        </ReactModal>
      </Home>
    )
  }

}


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: "120px",

  }
};

function mapStateToProps(state) {
  const { admin, user, companies, forms, question, option } = state;

  return { admin, user, companies, forms, question, option };
}

export default withRouter(connect(mapStateToProps)(Question));