import React, {Component} from "react";

export default class BrokenPage extends Component {
    render(){
        return(
            <div style={{textAlign: "center", marginTop: 150}}>
                <img src={require("../../images/broken.jpg")} alt="broken"/>
                {/* <h1>OOPS !</h1>
                <h5>The page you are searching for is not found</h5> */}
            </div>
        );
    }
}