import React,{Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import utils from "../../utils/utils.js";
import Home from "../home";
import { blogActions, formsActions } from '../../actions';
import ReactPaginate from 'react-paginate';
import "./index.scss";
import notyf from '../../utils/notification';
import Table from '../../component/table';
import { TopBarLoader } from '../../component';

class ListBlog extends Component {
    constructor(props){
        super(props);
        this.state = {
            activePage:1,
            offset:false,
            totalpages:0,
            processing:false
        };

        if(!utils.isLoggedIn()){
            window.location.href = "/login";
        }
    }

    rendernextpage=()=>{
        const {dispatch}=this.props;
        let page=this.state.activePage
           dispatch(blogActions.Getallblog(page+1))
           this.setState({offset:false})
      }
      handlePageChange=pageNumber=>{
        let pages=pageNumber.selected
         this.setState({activePage:pages,offset:true})
        }

   componentWillReceiveProps(nxtProps){
     const {companies,blog,dispatch}=nxtProps;
     let page=this.state.activePage;
     let companyPropsChanged = companies.status !== this.props.companies.status;
   
        if(companyPropsChanged && companies.status === "GET_SUCCESS")
        {
           dispatch(blogActions.Getallblog(page))
          
        }
        if(nxtProps.blog.status==="GET_ALLBLOG_SUCCESS")
        {
                let pages=blog.blogs.total_pages
                this.setState({totalpages:pages,processing:true})
        }
      if(nxtProps.blog.status==="DELETE_BLOG_SUCCESS"){
          notyf.open({type:"success",message:"SuccessFully Deleted your Blog"});
          dispatch(blogActions.Getallblog(page))
      }
    
   }
   handlePageChange=pageNumber=>{
    let pages=pageNumber.selected
     this.setState({activePage:pages,offset:true})
}

    render(){
        const {blog,dispatch}=this.props;
        document.title="Blog - MoonProduct"
        return(
        <Home>
            <div>
            {(blog.status==="GET_ALLBLOG_REQUEST"||blog.status==="DELETE_BLOG_REQUEST") && <TopBarLoader/>}
               <h1>Your Blog List</h1>
                <a href="/admin/createnewblog" style={{textDecoration:"none",color:"#fff"}}><button><i class="material-icons" style={{verticalAlign:"bottom"}}>post_add</i>Add New Blog</button></a>
               {blog.status==="GET_ALLBLOG_SUCCESS" &&
               <div>
                <Table content={blog.blogs.message} dispatch={dispatch}/>
               </div>}
              {this.state.processing && 
               <div className="pagination">
               <ReactPaginate
               previousLabel={'Previous'}
               nextLabel={'Next'}
               pageCount={this.state.totalpages}
               marginPagesDisplayed={0}
               pageRangeDisplayed={0}
               onPageChange={this.handlePageChange}
               disableInitialCallback={false}/> 
              </div>}
             {this.state.offset===true && this.rendernextpage()}
            </div>
        </Home>
        );
    }
}


function mapStateToProps(state) {
    const { admin, user,blog,companies } = state;
    return { admin, user,blog ,companies};
}
  
export default withRouter(connect(mapStateToProps)(ListBlog));