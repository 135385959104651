import React,{Component} from 'react';
import { blogActions } from '../../actions';

class Table extends Component{


  onDelete=(id)=>{
    const {dispatch={}}=this.props;
    dispatch(blogActions.deleteblog(id));

  }
    render(){
        const {content={}}=this.props;
        return(
           <div>
           {content.length>0 && 
           <table style={{border:0,backgroundColor:"white",borderRadius:10, boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)"}}>
              <tbody>
                  <tr style={{height:30,backgroundColor:"#26d493",color:"#fff"}}>
                    <td></td>
                    <td>Image</td>
                    <td>Name</td>
                    <td>Edit</td>
                    <td>Delete</td>
                  </tr>
                  {content.map((k,index)=>{
                      return( 
                     <tr> 
                      <td>{index+1}</td>
                      <td><img src={k.image}style={{height:30,borderRadius:0,width:50}}/></td>
                      <td>{k.title}</td>
                      <a href={`/admin/editblog/`+k.url.replace(" ","-")} style={{textDecoration:"none"}}><td ><i class="material-icons">create</i></td></a>
                      <td onClick={()=>this.onDelete(k.id)}><i class="material-icons" style={{color:"#f03a3a",cursor:"pointer"}}>delete_forever</i></td>
                     </tr>
                          )})} 
               </tbody>
           </table>}
           {content.length===0&&
             <div>
              <p style={{textAlign:"center"}}>Admin.,You Didn't Post Any Blog in your website.</p>
              <p style={{textAlign:"center"}}>Make a Blog Post to Enhanced your Website.</p>

            </div>
           }
           </div>
        )
    }
}
export default Table;