import {QuestionConstants} from "../constants";

const initialState ={
    status:""
}

export function question(state= initialState,action){
     switch(action.type){
         case QuestionConstants.ADD_REQUEST:
             return {
                    ...state,
                     status:"ADD_QUESTION_REQUEST"
                   };
         case QuestionConstants.ADD_SUCCESS:
             return {
                 ...state,
                 status:"ADD_QUESTION_SUCCESS",
                 questions:action.message
             };
        case QuestionConstants.ADD_ERROR:
            return {
                    ...state,
                    status:"ADD_QUESTION_ERROR",
                    questions:action.error
                };
       case QuestionConstants.GET_REQUEST:
            return{
                        ...state,
                        status:"GET_QUESTION_REQUEST"
                    };
       case QuestionConstants.GET_SUCCESS:
            return {
                        ...state,
                        status:"GET_QUESTION_SUCCESS",
                        questions:action.message
                    };
        case QuestionConstants.GET_ERROR:
              return {
                           ...state,
                           status:"GET_QUESTION_ERROR",
                           questions:action.error
                     };      
       case QuestionConstants.EDIT_REQUEST:
              return{
                         ...state,
                         status:"EDIT_QUESTION_REQUEST"
                     };
        case QuestionConstants.EDIT_SUCCESS:
               return {
                         ...state,
                         status:"EDIT_QUESTION_SUCCESS",
                         questions:action.message
                     };
       case QuestionConstants.EDIT_ERROR:
              return {
                            ...state,
                            status:"EDIT_QUESTION_ERROR",
                            questions:action.error
                     };
        case QuestionConstants.DELETE_REQUEST:
              return{
                            ...state,
                            status:"DELETE_QUESTION_REQUEST"
                     };
       case QuestionConstants.DELETE_SUCCESS:
             return {
                            ...state,
                            status:"DELETE_QUESTION_SUCCESS",
                            questions:action.message
                    };
        case QuestionConstants.DELETE_ERROR:
             return {
                               ...state,
                               status:"DELETE_QUESTION_ERROR",
                               questions:action.error
                    };
        default:
            return state;
        
     }
}