import {QuestionService} from "../services";
import {QuestionConstants} from "../constants";

export const QuestionActions ={
    addQuestions,
    getAllQuestion,
    editQuestions,
    deleteQuestions
}

function addQuestions(payload){
    return dispatch =>{
        dispatch(request());
        QuestionService.addQuestion(payload).then(
            message => dispatch(success(message)),
            error   => dispatch(failure(error))
        );
    };
    
    function request(){
        return {type:QuestionConstants.ADD_REQUEST};
    }
    function success(message){
        return {type:QuestionConstants.ADD_SUCCESS,message};
    }
    function failure(error){
        return {type:QuestionConstants.ADD_ERROR,error};
    }
    
}

function getAllQuestion(formid){
    return dispatch =>{
        dispatch(request());
        QuestionService.getAllQuestion(formid).then(
            message => dispatch(success(message)),
            error   => dispatch(failure(error))
        );
    };
    
    function request(){
        return {type:QuestionConstants.GET_REQUEST};
    }
    function success(message){
        return {type:QuestionConstants.GET_SUCCESS,message};
    }
    function failure(error){
        return {type:QuestionConstants.GET_ERROR,error};
    }
    
}

function editQuestions(payload){
    return dispatch =>{
        dispatch(request());
        QuestionService.editQuestion(payload).then(
            message => dispatch(success(message)),
            error   => dispatch(failure(error))
        );
    };
    
    function request(){
        return {type:QuestionConstants.EDIT_REQUEST};
    }
    function success(message){
        return {type:QuestionConstants.EDIT_SUCCESS,message};
    }
    function failure(error){
        return {type:QuestionConstants.EDIT_ERROR,error};
    }
    
}

function deleteQuestions(id){
    return dispatch =>{
        dispatch(request());
        QuestionService.deleteQuestion(id).then(
            message => dispatch(success(message)),
            error   => dispatch(failure(error))
        );
    };
    
    function request(){
        return {type:QuestionConstants.DELETE_REQUEST};
    }
    function success(message){
        return {type:QuestionConstants.DELETE_SUCCESS,message};
    }
    function failure(error){
        return {type:QuestionConstants.DELETE_ERROR,error};
    }
    
}
