import {widgetConstants} from "../constants";

const initialState ={
     
    config: {},
    error: {},
    updation: {}

};

export function widget(state =initialState,action){
    switch(action.type){
        case widgetConstants.GET_JSON_REQUEST:
            return {
                ...state,
                status:"GET_JSON_REQUEST"
            };
        case widgetConstants.GET_JSON_SUCCESS:
            return {
                ...state,
                status:"GET_JSON_SUCCESS",
                config:action.res
            }
        case widgetConstants.GET_JSON_ERROR:
            return {
                ...state,
                status:"GET_JSON_ERROR",
                error:action.error
            }
        case widgetConstants.UPDATE_JSON_REQUEST:
            return {
                ...state,
                status:"UPDATE_JSON_REQUEST"
            };
        case widgetConstants.UPDATE_JSON_SUCCESS:
            return {
                ...state,
                status:"UPDATE_JSON_SUCCESS",
                updation: action.res
            }
        case widgetConstants.UPDATE_JSON_ERROR:
            return {
                ...state,
                status:"UPDATE_JSON_ERROR",
                error:action.error
            }
        default:
            return state;
    }
}