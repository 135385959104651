import {httpClient} from "../utils";

export const QuestionOptionService ={
    addOption,
    getAllOption,
    editOption,
    deleteOption
}

function addOption(payload){
    return httpClient.callApi("POST",`/admin/configure/options`,payload)
}

function getAllOption(questionid){
    return httpClient.callApi("GET",`/admin/configure/options/${questionid}`)
}

function editOption(payload){
    return httpClient.callApi("PUT",`/admin/configure/options`,payload)
}

function deleteOption(id){
    return httpClient.callApi("DELETE",`/admin/configure/options`)
}