import {taskstatusConstants} from "../constants";

const initialState ={
    taskstatus:[]
};

export function taskstatus(state =initialState,action){
    switch (action.type){
        case taskstatusConstants.ADDING_TASKSTATUS_REQUEST:
            return{
                ...state,
                status:"ADDING_TASKSTATUS_REQUEST",
            };
        case taskstatusConstants.ADDING_TASKSTATUS_SUCCESS:
            return{
                ...state,
                status:"ADDING_TASKSTATUS_SUCCESS",
                taskstatus:action.taskstatus
            };
        case taskstatusConstants.ADDING_TASKSTATUS_ERROR:
            return{
                ...state,
                status:"ADDING_TASKSTATUS_ERROR",
                taskstatus:action.error
            };
        case taskstatusConstants.GETTING_TASKSTATUS_REQUEST:
            return{
                ...state,
                status:"GETTING_TASKSTATUS_REQUEST",
            }; 
        case taskstatusConstants.GETTING_TASKSTATUS_SUCCESS:
            return{
                ...state,
                status:"GETTING_TASKSTATUS_SUCCESS",
                taskstatus:action.taskstatus
            };
        case taskstatusConstants.GETTING_TASKSTATUS_ERROR:
                return{
                    ...state,
                    status:"GETTING_TASKSTATUS_ERROR",
                    taskstatus:action.error
                };   
        case taskstatusConstants.UPDATING_TASKSTATUS_REQUEST:
            return {
                ...state,
                status:"UPDATING_TASKSTATUS_REQUEST"
            };
        case taskstatusConstants.UPDATING_TASKSTATUS_SUCCESS:
            return {
                ...state,
                status:"UPDATING_TASKSTATUS_SUCCESS",
                taskstatus:action.taskstatus
            };
        case taskstatusConstants.UPDATING_TASKSTATUS_ERROR:
            return {
                ...state,
                status:"UPDATING_TASKSTATUS_ERROR",
                taskstatus:action.error
            }
            default:
                return state;
    }
}