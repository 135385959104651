export const adminConstants ={
    ADMIN_REQUEST:"MAKE_ME_ADMIN_REQUEST",
    ADMIN_SUCCESS:"MAKE_ME_ADMIN_SUCCESS",
    ADMIN_ERROR:"MAKE_ME_ADMIN_ERROR",

    GET_SUB_REQUEST: "GET_SUBSCRIPTION_REQUEST",
    GET_SUB_SUCCESS: "GET_SUBSCRIPTION_SUCCESS",
    GET_SUB_ERROR: "GET_SUBSCRIPTION_ERROR",

    GET_TRANS_REQUEST: "GET_TRANSACTION_DETAIL_REQUEST",
    GET_TRANS_SUCCESS: "GET_TRANSACTION_DETAIL_SUCCESS",
    GET_TRANS_ERROR: "GET_TRANSACTION_DETAIL_ERROR",

    SUB_INFO_REQUEST: "GET_SUBSCRIPTION_INFORMATION_REQUEST",
    SUB_INFO_SUCCESS: "GET_SUBSCRIPTION_INFORMATION_SUCCESS",
    SUB_INFO_ERROR: "GET_SUBSCRIPTION_INFORMATION_ERROR",

    CHANGE_SUB_REQUEST: "CHANGE_SUBSCRIPTION_REQUEST",
    CHANGE_SUB_SUCCESS: "CHANGE_SUBSCRIPTION_SUCCESS",
    CHANGE_SUB_ERROR: "CHANGE_SUBSCRIPTION_ERROR",
}