import {taskviewConstants} from "../constants";
import {taskviewService} from "../services";

export const taskviewActions ={
    View,
    changetaskstatus
};

function View(payload,refresh= false){
    return dispatch =>{
        dispatch(request(refresh));
        taskviewService.View(payload).then(
            res =>{
                dispatch(success(res))
            },
            error =>{
                dispatch(failure(error));
            }
        );
    };
    function request(refresh){
        return {type:taskviewConstants.TASKVIEW_REQUEST,refresh};
    }
    function success(task){
        return {type:taskviewConstants.TASKVIEW_SUCCESS,task};
    }
    function failure(error){
        return {type:taskviewConstants.TASKVIEW_ERROR,error};
    }
}

function changetaskstatus(payload){
    return dispatch =>{
        dispatch(request());
        taskviewService.changetaskstatus(payload).then(
            res =>{
                dispatch(success(res))
            },
            error =>{
                dispatch(failure(error));
            }
        );
    };
    function request(){
        return {type:taskviewConstants.CHANGE_TASKSTATUS_REQUEST};
    }
    function success(task){
        return {type:taskviewConstants.CHANGE_TASKSTATUS_SUCCESS,task};
    }
    function failure(error){
        return {type:taskviewConstants.CHANGE_TASKSTATUS_ERROR,error};
    }
}
