import React,{Component} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { adminActions } from "../../actions";
import "../home/index.css";
import utils from "../../utils/utils.js";
import {  PricingCard } from "../../component";
import Home from "../home";
import notyf from "../../utils/notification";

class Pricing extends Component {
    constructor(props){
        super(props);
        this.state = {
            subscribed: false,
            plan: "",
            active: "",
            plan_id: "",
            sId: "",
            wait: true
        };

        if(!utils.isLoggedIn()){
            window.location.href = "/login";
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(adminActions.getPlans());
    }

    componentWillReceiveProps(prevprops){
        const { admin, user,dispatch, companies } = prevprops;
        let adminPropChanged = admin.status !== this.props.admin.status;
        let userPropChanged = user.status !== this.props.user.status;

        if(adminPropChanged){
            if(admin.status === "ADMIN_SUCCESS"){
                utils.paymentRedirection(admin.admin.stripe_session_id);
            }else if(admin.status === "ADMIN_ERROR"){
                notyf.open({ type: "error", message: admin.error.messaage });
            }
            if(admin.status === "SUB_INFO_SUCCESS"){
                let tr = admin.transaction;
                this.setState({ 
                                active: tr.active,
                                plan_id: tr.plan_id,
                                sId: tr.stripe_session_id,
                                wait: false
                             });
            }else if(admin.status === "SUB_INFO_ERROR"){
                let tr = admin.error;
                this.setState({ 
                                active: tr.active,
                                plan_id: tr.plan_id ,
                                sId: tr.stripe_session_id,
                                wait: false
                             });
                notyf.open({ type: "error", message: admin.error.message });
            }
            if(admin.status === "CHANGE_SUB_SUCCESS"){
                let tr = admin.transaction;
                if(tr.session_id !== null){
                    utils.paymentRedirection(tr.session_id);             
                    this.setState({ sId: tr.session_id });
                }else {
                    window.location.reload(true);
                }
            }
        }
        let companyPropsChanged = companies.status !== this.props.companies.status;

        if(user.status === "PROFILE_SUCCESS" && userPropChanged){
            this.setState({ subscribed: user.profile.profile.is_admin, wait: false });
        }
        if(companyPropsChanged && companies.status === "GET_SUCCESS"){
            if(user.profile.profile.is_admin){
                this.setState({ wait: true });
                dispatch(adminActions.getSubscriptionInfo())
            }
        }
    }

    makemeadmin = (id) => {
        const { user, dispatch } = this.props;
        try{
            let prof = user.profile.profile;
            let payload = {
                "phone_number": prof.phone_number , 
                "name": prof.name,
                "mail": prof.email_id,
                "type":"manual",
                "plan_id": id
            }
            this.setState({ plan: id}, 
            () => dispatch(adminActions.makemeadmin(payload))  );
        }catch(e){
            alert("Something Went wrong... Please Reload the page.")
        }
    }


    renderText = () => {
        const { admin } = this.props;

        if(admin.status === "ADMIN_REQUEST") return "Subscribing...";
        else return "Subscribe & Pay";
    }

    render(){
        const { admin, dispatch } = this.props;
        let icons = ["fa fa-paper-plane", "fa fa-plane", "fa fa-rocket"];
        let plans = admin.subscription;
        let price = plans.length != 0 ? plans.plans.map( (e,index) => {
            return {
                "icon": icons[index%3],
                "title": e.nick_name,
                "price_symbol": e.currency,
                "price": e.amount/100,
                "options":  [],
                "plan_id": e.plan_id
            }
        }) : [];

        return (
           <Home>
                <div className="grid">
                        {!this.state.wait && price.length != 0 && price.map( swp =>  {
                            return <PricingCard 
                                        details={swp} 
                                        onclick={(detail) => this.makemeadmin(detail)}
                                        disableButton = {this.state.subscribed}
                                        buttonText = {this.renderText()}
                                        plan={this.state.plan}
                                        active={this.state.active}
                                        plan_name={this.state.plan_id}
                                        session={this.state.sId}
                                        changedPlan={ (payload) => dispatch(adminActions.changePlan(payload))}
                                    />;
                            }
                        )}
                </div>
            </Home>
        )
    }    
}

function mapStateToProps(state) {
    const { admin, user, companies } = state;
    return { admin, user, companies };
  }
  
  export default withRouter(connect(mapStateToProps)(Pricing));