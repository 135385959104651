import {formsService} from "../services";
import {formsConstants} from "../constants";

export const formsActions ={
    getForms,
    addNewForms,
    editForm,
    deleteForm
};

function getForms(){
    return dispatch =>{
        dispatch(request());
        formsService.getForms()
        .then(res => dispatch(success(res)),err =>dispatch(error(err)));
    };
    function request(){
        return {type: formsConstants.GET_REQUEST};
    }
    function success(res){
        return{type:formsConstants.GET_SUCCESS,res};
    }
    function error(err){
        return{type:formsConstants.GET_ERROR, err};
    }
}

function addNewForms(payload){
    return dispatch =>{
        dispatch(request());
        formsService.addNewForms(payload).then(
            res => dispatch(success(res)),
            err => dispatch(error(err))
        );
    };
    function request(){
        return {type: formsConstants.ADD_REQUEST};
    }
    function success(res){
        return {type: formsConstants.ADD_SUCCESS,res};
    }
    function error(err){
        return {type:formsConstants.ADD_ERROR,err}
    }
}

function editForm(payload){
    return dispatch =>{
        dispatch(request());
        formsService.editForm(payload).then(
            res => dispatch(success(res)),
            err => dispatch(error(err))
        );
    };
    function request(){
        return {type: formsConstants.EDIT_REQUEST};
    }
    function success(res){
        return {type: formsConstants.EDIT_SUCCESS,res};
    }
    function error(err){
        return {type:formsConstants.EDIT_ERROR,err}
    }
}

function deleteForm(payload){
    return dispatch =>{
        dispatch(request());
        formsService.deleteForm(payload).then(
            res => dispatch(success(res)),
            err => dispatch(error(err))
        );
    };
    function request(){
        return {type: formsConstants.DELETE_REQUEST};
    }
    function success(res){
        return {type: formsConstants.DELETE_SUCCESS,res};
    }
    function error(err){
        return {type:formsConstants.DELETE_ERROR,err}
    }
}


