import React,{Component} from 'react';
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import "../../index.css";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { authenticationActions } from "../../actions";
import notyf from "../../utils/notification.js";
import utils  from "../../utils/utils";

class Login extends Component {  
    constructor(props){
       super(props);
       this.state = {
         phone_number: "",
         otp: "",
         loading: false,
         error: false,
         flow: "mobile"
       }
       if(utils.isLoggedIn()){
         window.location.href = "/taskboard";
      }

       this.componentDidMount = this.componentDidMount.bind(this);
       this.onChange = this.onChange.bind(this);
       this.onSubmit = this.onSubmit.bind(this);
     }

     

     componentDidMount() {
     }

     onChange(e){
        this.setState({[e.target.name]:e.target.value, error: false});
     }

     isAvailable = (e) => {
            e.preventDefault();
            const { dispatch } = this.props;
            const { phone_number } = this.state;
            if(isValidPhoneNumber(phone_number)){
               let payload ={ phone_number };
               dispatch(authenticationActions.isAvailable(payload));
            }else {
               notyf.open({type: 'error', message: "Enter valid Phone number with country code."});
            }
            
      }

      forOtp = () => {
            const { dispatch } = this.props;
            const { phone_number } = this.state;
            if(isValidPhoneNumber(phone_number)){
               let payload ={ phone_number };
               dispatch(authenticationActions.sendOtp(payload));
            }else {
               notyf.open({type: 'error', message: "Enter valid Phone number with country code."});
            }
      }

     componentWillReceiveProps(nextProps){
        const prevProps = this.props ;
        const { history } = this.props; 
        let hasPropsChanged = prevProps.authentication.status !== nextProps.authentication.status;

        if(hasPropsChanged){
         if(nextProps.authentication.status === "SENDINGOTP_SUCCESS"){
            this.setState({ flow: "otp" });
            notyf.open({type:'success', message:"OTP sent to your Email"});
         }
         if(nextProps.authentication.status === "ISAVAILABLE_SUCCESS"){
            if(nextProps.authentication.available["is_available"]){ 
               notyf.open({type:'error', message:"Email Doesnot exist. Try Creating Account."});
            }
            else this.forOtp();
         }
         if(nextProps.authentication.status === "LOGIN_SUCCESS"){
            history.push("/widget");
         }
         if(nextProps.authentication.status.indexOf("ERROR") != -1)
            notyf.open({type: 'error', message: nextProps.authentication.error.message})

        }
     }


     onSubmit(e) {
       const { dispatch } =this.props;
       const { phone_number, otp } = this.state;
        e.preventDefault();
        if(phone_number === "" || otp === "") this.setState({ error: true });
        else {
            let payload = {
               phone_number,
               otp
            } 
            dispatch(authenticationActions.login(payload, "login_otp"));
        }
                 
    }        


    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value.trim(), error: false });
    }

    renderText = () => {
       const { authentication } = this.props;
         if(authentication.status === "ISAVAILABLE_REQUEST") return "CHECKING....";
         else if(authentication.status === "SENDINGOTP_REQUEST") return "SENDING OTP...";
         else if(authentication.status === "LOGIN_REQUEST") return "VERIFING....";
         else return "LOG IN";
    }


    render()
    {
        const { error, flow } = this.state;
        return (
         <div id="main">
           <section class="Login">
              <div class="Login__logo">
                 <span>
                 </span>
              </div>
              <h2 class="Login__title">Please log in to your account</h2>
              <div class="Login__box Login__box--progress">
                 <form class="Login__form" action="#" method="POST" >
                    <div class="Message Message-warning alert alert-block alert-warning Message--withMargin"></div>
                    <div class={ error ? "Message-danger alert alert-block alert-danger Message--withMargin " : "hide"}>
                       {
                          error && "Invalid Credentials"
                       }
                    </div>
                    <label class="Login__formRow">
                    <PhoneInput
                        defaultCountry="IN"
                        name="phone_number"
                        className={error ? "input input-error" : "input"}
                        placeholder="Mobile Number"
                        value={this.state.phone_number}
                        style={{height:"30"}}
                        onChange={phone_number => this.setState({ phone_number: phone_number !== "undefined" ? phone_number : "" })} 
                     />
                    {/* <input type="text" name="phone_number" autocomplete="mobile phone" required  value={this.state.phone_number} onChange={this.onChange}/> */}
                    </label>
                    { flow !== "mobile" &&
                     <label class="Login__formRow mgt2"><span>OTP</span>
                        <input autocomplete="off" type="number" name="otp" required  value={this.state.otp} onChange={this.onChange} maxLength={8}/>
                     </label>
                     }
                    <div class="Login__formActions mgt3">
                        <div onClick={this.forOtp}><a style={{cursor: "pointer"}}>Resend OTP?</a></div>
                        <button class="btn btn-primary LeftNav__addButton--right"  onClick={flow != "mobile" ? this.onSubmit : this.isAvailable}>
                           { this.renderText() }
                        </button>
                        {/* <a href="/logincredentials"> Login with Username </a>  */}
                        <a href="/loginWithEmail"> <span style={{color: "#007bff"}}> Login with Email </span></a> 
                    </div>
                    {/* <div className="Login__formActions">
                        <button class="btn btn-primary LeftNav__addButton--right"  onClick={flow != "mobile" ? this.onSubmit : this.isAvailable}>
                           { this.renderText() }
                        </button>
                    </div> */}
                 </form>
                 <div class="Login__footer"><span>Don't have an account?</span><a class="btn" href="signup" data-browseronly="true" data-bypass="true">Create an Account</a></div>
              </div>
           </section>
        </div>
       );   
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
  }
  
export default withRouter(connect(mapStateToProps)(Login));