import {formsConstants} from "../constants";
const initialState ={
    status: ""
};

export function forms(state =initialState,action){
    switch(action.type){
        case formsConstants.GET_REQUEST:
            return{
                ...state,
                status:"GET_REQUEST"
            };
        case formsConstants.GET_SUCCESS:
            return {
                ...state,
                status: "GET_SUCCESS",
                list: action.res
            };
        case formsConstants.GET_ERROR:
            return {
                ...state,
                status: "GET_ERROR",
                error: action.err
            };
            case formsConstants.ADD_REQUEST:
                return{
                    ...state,
                    status:"ADDING_FORMS_REQUEST"
                };
            case formsConstants.ADD_SUCCESS:
                return {
                    ...state,
                    status: "ADDING_FORMS_SUCCESS",
                    list: action.res
                };
            case formsConstants.ADD_ERROR:
                return {
                    ...state,
                    status: "ADDING_FORMS_ERROR",
                    error: action.err
                };
            case formsConstants.EDIT_REQUEST:
                    return{
                        ...state,
                        status:"EDITING_FORMS_REQUEST"
                    };
            case formsConstants.EDIT_SUCCESS:
                    return {
                        ...state,
                        status: "EDITING_FORMS_SUCCESS",
                        list: action.res
                    };
            case formsConstants.EDIT_ERROR:
                    return {
                        ...state,
                        status: "EDITING_FORMS_ERROR",
                        error: action.err
                    };
           case formsConstants.DELETE_REQUEST:
                        return{
                            ...state,
                            status:"DELETE_FORMS_REQUEST"
                        };
            case formsConstants.DELETE_SUCCESS:
                        return {
                            ...state,
                            status: "DELETE_FORMS_SUCCESS",
                            list: action.res
                        };
            case formsConstants.DELETE_ERROR:
                        return {
                            ...state,
                            status: "DELETE_FORMS_ERROR",
                            error: action.err
                        };
        default:
            return state;
    }
}