import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import '../../index.css';
import utils from "../../utils/utils.js";
import notyf from "../../utils/notification.js";
import { adminActions, companyActions } from '../../actions';
import Home from "../home";
import IframeCode from '../widget/iframecode';
import Widgetform from "../widget/widgetform";
import "./index.scss";



class PaymentStatus extends Component {  
    constructor(props){
       super(props);
       this.state = {
           status : utils.getParameterByName("status", window.location.href),
           sessionId : utils.getParameterByName("sessionId", window.location.href),
           error: false
        }

        if(!utils.isLoggedIn()){
            window.location.href = "/login";
        }

    }

     

     componentDidMount() {
        const { dispatch } = this.props;
        const { sessionId } = this.state;

        if(![NaN,null,"",undefined].includes(sessionId)){
            dispatch(adminActions.getTransactionDetail(sessionId));
            dispatch(companyActions.getCompanies());
        }
        else {
            this.setState({ error: true });
            notyf.open({type: "error", message: "Invalid Link"});
        }
     }

    

    render() {
        const { admin,companies } =this.props;
        return (
              <Home>
                {
                    admin.status === "GET_TRANS_SUCCESS" && 
                    admin.transaction.Paid &&
                     <div> 
                     <div class="particles">
                        {/* <div class="before"></div> */}
                        <div style={{backgroundColor: "#592", height: "100px", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "row"}}>
                            <i class="material-icons" style={{color:"#fff",fontSize: 40, padding:"0px 8px"}}>done</i>
                            <p style={{color: "#fff", fontSize: 20}}>Transaction Successful</p>
                        </div>
                        {/* <div class="after"></div> */}
                        <div style={{width: "70%", marginLeft: "auto", marginRight: "auto", marginTop: 100}}>
                            <div style={{textAlign: "center", marginBottom: 20}}>
                                <p style={{fontSize: 20}}>You can now copy the widget iframe code and link with your website at the ease...</p>
                            
                            </div>
                            <IframeCode />
                           {companies.status==="GET_SUCCESS"&& <Widgetform />}
                            </div>
                        </div>
                     </div> 
                  } 
                {   admin.status === "GET_TRANS_SUCCESS" && !admin.transaction.Paid &&
                    <div>
                        <div style={{backgroundColor: "#e44", height: "100px", borderRadius: "50px", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "row"}}>
                                <i class="material-icons" style={{color:"#fff",fontSize: 40, padding:"0px 8px"}}>query_builder</i>
                                <p style={{color: "#fff", fontSize: 20}}>Payment Pending</p>
                        </div>
                        <div>
                            <h2 class="mb2 Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--500" style={{ width: "50%", margin: "auto", textAlign: "center", paddingTop: 50}}>
                                <span>If you have paid and money detected from your account, your subscription will be activated within 8 hours, else the money would be refunded in your account within 8 working days</span>
                            </h2>
                        </div>
                    </div>
                 }
                {
                    (admin.status === "GET_TRANS_ERROR" || this.state.error) &&
                    <div class="FullPageMessage-Message center">
                        <h2 class="mb2 Text Text-color--gray400 Text-fontSize--16 Text-fontWeight--500">
                            <span>Invalid link</span>
                        </h2>
                        <p class="mb2 FullPageMessage-Message-Detail Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                            <span>The Link you triggered was invalid.</span>
                        </p>
                        <p class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                            <a class="Link Link--primary" href="/home" target="_self"><span>Back</span></a>
                        </p>
                    </div>
                 }
            </Home>
            
                  
        );
    }
}

function mapStateToProps(state) {
  const { admin ,companies} = state;

  return { admin ,companies};
}

export default withRouter(connect(mapStateToProps)(PaymentStatus));