import {userConstants} from "../constants";
import {userService} from "../services";

export const userActions ={
    profile
};


function profile(){
    return dispatch =>{
        dispatch(request());
        userService.profile().then(
            user =>{ dispatch(success(user)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return {type:userConstants.PROFILE_REQUEST};
    }
    function success(user){
        return {type:userConstants.PROFILE_SUCCESS,user};
    }
    function failure(error){
        return {type:userConstants.PROFILE_ERROR,error};
    }
}
