import {QuestionOptionConstants} from "../constants";

const initialState ={
    status:""
}

export function option(state= initialState,action){
     switch(action.type){
         case QuestionOptionConstants.ADD_REQUEST:
             return {
                    ...state,
                     status:"ADD_OPTION_REQUEST"
                   };
         case QuestionOptionConstants.ADD_SUCCESS:
             return {
                 ...state,
                 status:"ADD_OPTION_SUCCESS",
                 options:action.message
             };
        case QuestionOptionConstants.ADD_ERROR:
            return {
                    ...state,
                    status:"ADD_OPTION_ERROR",
                    options:action.error
                };
       case QuestionOptionConstants.GET_REQUEST:
            return{
                        ...state,
                        status:"GET_OPTION_REQUEST"
                    };
       case QuestionOptionConstants.GET_SUCCESS:
            return {
                        ...state,
                        status:"GET_OPTION_SUCCESS",
                        options:action.message
                    };
        case QuestionOptionConstants.GET_ERROR:
              return {
                           ...state,
                           status:"GET_OPTION_ERROR",
                           options:action.error
                     };      
       case QuestionOptionConstants.EDIT_REQUEST:
              return{
                         ...state,
                         status:"EDIT_OPTION_REQUEST"
                     };
        case QuestionOptionConstants.EDIT_SUCCESS:
               return {
                         ...state,
                         status:"EDIT_OPTION_SUCCESS",
                         options:action.message
                     };
       case QuestionOptionConstants.EDIT_ERROR:
              return {
                            ...state,
                            status:"EDIT_OPTION_ERROR",
                            options:action.error
                     };
        case QuestionOptionConstants.DELETE_REQUEST:
              return{
                            ...state,
                            status:"DELETE_OPTION_REQUEST"
                     };
       case QuestionOptionConstants.DELETE_SUCCESS:
             return {
                            ...state,
                            status:"DELETE_OPTION_SUCCESS",
                            options:action.message
                    };
        case QuestionOptionConstants.DELETE_ERROR:
             return {
                               ...state,
                               status:"DELETE_OPTION_ERROR",
                               options:action.error
                    };
        default:
            return state;
        
     }
}