import React,{Component} from 'react';
import { formsActions } from '../../actions';
import ReactModal from "react-modal";
import "./index.css";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      transform             : 'translate(-50%, -50%)',
      padding               :  "120px",
      
    }
  };



class FormTable extends Component{
    constructor () {
        super();
        this.state = {
          showModal: false,
          Name:"",
          Id:"",
          processing:false
        };
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
      }


      handleOpenModal =(title,id)=> {
        this.setState({ showModal: true,Name:title,Id:id });
      }
      
      handleCloseModal=()=>{
        this.setState({ showModal: false });
      }

      onChange(e){
        this.setState({[e.target.name]:e.target.value});
      }

     onSubmit=()=>{
         const {dispatch={}}=this.props;
         let title=this.state.Name;
         let id=this.state.Id;
         let payload={
             "id":id,
             "title":title
         }
         dispatch(formsActions.editForm(payload))
         
         this.handleCloseModal()
     }
     
     onDelete=(id)=>{
        const {dispatch={}}=this.props;
        let Id=id
        let payload={
            "id":Id
        }
        dispatch(formsActions.deleteForm(payload))
     }
      
    render(){
        const {content={}, companies}=this.props;
        return(
           <div>
           {content.length>0 && 
           <table style={{border:0, backgroundColor:"white",borderRadius:10, boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)"}}>
              <tbody>
                  <tr style={{height:30,backgroundColor:"#26d493",color:"#fff"}}>
                    <td>  </td>
                    <td></td>
                    <td >Form Name </td>
                    <td>Created At</td>
                    <td>Form Widget Code</td>
                    <td>Ads Code</td>

                    
                  </tr>
                  {content.map((k,index)=>{
                      let date = new Date( Date.parse(k.created_at))   
                      let sourceForm = `https://development.moonproduct.in?id=${companies.list.message[0].client_id}&form=${k.id}`;
                      let sourceAds = `https://development.moonproduct.in/ads?id=${companies.list.message[0].client_id}&form=${k.id}`;
                      return( 
                     <tr> 
                      <td>
                        <div class="dropdown">
                          <div class="dropbtn" style={{ cursor: "pointer" }}>
                            <i class="material-icons">more_vert</i>
                          </div>
                          <div class="dropdown-content">
                            <div style={{ cursor: "pointer" }} onClick={() => this.handleOpenModal(k.title, k.id)}>
                              <a><i class="material-icons" style={{ fontSize: 15 }}>edit</i>{""} Edit</a>
                            </div>
                            <a href={`/admin/question/` + k.title.replace(" ", "-") + `/` + k.id}> <i class="material-icons" style={{ fontSize: 15 }}>note_add</i>{""} Question</a>
                            <a href="/widget"> <i class="material-icons" style={{ fontSize: 15}}>settings</i>{""} Widget</a>
                            <div style={{ cursor: "pointer" }} onClick={() => this.onDelete(k.id)}><a> <i class="material-icons" style={{ fontSize: 15 }}>delete</i>{""} Delete</a></div>
                          </div>
                        </div>
                      </td>
                      <td>{index+1}</td>
                      <td>{k.title}</td>
                      <td>{date.toDateString()},{date.toLocaleTimeString()}</td>
                      <td><code>
                        {` <iframe src="${sourceForm}" frameBorder=0 width="100%" height="100%"> </iframe> `}
                      </code></td>
                      <td><code>
                        {`${sourceAds}`}
                      </code></td>

                     </tr>
                          )})} 
               </tbody>
           </table>}
           {content.length===0&&
             <div>
              <p style={{textAlign:"center"}}>Admin,you didn't have any images in your pages</p>
              <p style={{textAlign:"center"}}>Make an image upload to get an url.</p>

            </div>
           }
            <ReactModal 
            isOpen={this.state.showModal}
            contentLabel="onRequestClose Example"
            onRequestClose={this.handleCloseModal}
            style={customStyles}
         >
            <h1>Edit your Form Title</h1>
            <div class="input-container">		
            <input type="text"name="Name" value={this.state.Name} onChange={e=>this.onChange(e)}/>
            </div>
           <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
               <div>
               <button onClick={()=>this.handleCloseModal()}>Cancel</button>
                  
               </div>
              <div >
              <button onClick={()=>this.onSubmit()}>Save</button>
               </div>
              </div>
         </ReactModal>
           </div>
        )
    }
}

function mapStateToProps(state) {
  const { user,companies} = state;
  return { user ,companies};
}

export default withRouter(connect(mapStateToProps)(FormTable));