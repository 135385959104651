import {httpClient} from "../utils";

export const taskviewService ={
    View,
    changetaskstatus
}

function View(payload){
   
    return httpClient.callApi("POST",`/admin/list_tasks`,payload);
}

function changetaskstatus(payload){
    return httpClient.callApi("POST",`/admin/set_task_status`,payload);
}