export  const taskstatusConstants ={
     ADDING_TASKSTATUS_REQUEST:"ADDING_TASKSTATUS_REQUEST",
     ADDING_TASKSTATUS_SUCCESS:"ADDING_TASKSTATUS_SUCCESS",
     ADDING_TASKSTATUS_ERROR:"ADDING_TASKSTATUS_ERROR",

     GETTING_TASKSTATUS_REQUEST:"GETTING_TASKSTATUS_REQUEST",
     GETTING_TASKSTATUS_SUCCESS:"GETTING_TASKSTATUS_SUCCESS",
     GETTING_TASKSTATUS_ERROR:"GETTING_TASKSTATUS_ERROR",

     UPDATING_TASKSTATUS_REQUEST:"UPDATING_TASKSTATUS_REQUEST",
     UPDATING_TASKSTATUS_SUCCESS:"UPDATING_TASKSTATUS_SUCCESS",
     UPDATING_TASKSTATUS_ERROR:"UPDATING_TASKSTATUS_ERROR"
}