export const widgetConstants ={
    GET_JSON_REQUEST:"GET_JSON_REQUEST",
    GET_JSON_SUCCESS:"GET_JSON_SUCCESS",
    GET_JSON_ERROR:"GET_JSON_ERROR",

    UPDATE_JSON_REQUEST:"UPDATE_JSON_REQUEST",
    UPDATE_JSON_SUCCESS:"UPDATE_JSON_SUCCESS",
    UPDATE_JSON_ERROR:"UPDATE_JSON_ERROR",


}