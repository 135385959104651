import {  settingsConstants } from "../constants";
import {  settingsService   } from "../services";

export const settingsActions ={
    getSmsEmailNotification,
    putSmsEmailNotification
};


function getSmsEmailNotification(){
    return dispatch =>{
        dispatch(request());
        settingsService.getSmsEmailNotification().then(
            notify =>{ dispatch(success(notify)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return {type: settingsConstants.GET_SMSEMAIL_REQUEST};
    }
    function success(notify){
        return {type: settingsConstants.GET_SMSEMAIL_SUCCESS, notify};
    }
    function failure(error){
        return {type: settingsConstants.GET_SMSEMAIL_ERROR, error};
    }
}

function putSmsEmailNotification(payload){
    return dispatch =>{
        dispatch(request());
        settingsService.putSmsEmailNotification(payload).then(
            notify =>{ dispatch(success(notify)) },
            error =>{ dispatch(failure(error)) }
        );
    };
    function request(){
        return {type: settingsConstants.PUT_SMSEMAIL_REQUEST};
    }
    function success(notify){
        return {type: settingsConstants.PUT_SMSEMAIL_SUCCESS, notify};
    }
    function failure(error){
        return {type: settingsConstants.PUT_SMSEMAIL_ERROR, error};
    }
}