import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const notyf = new Notyf({
    types: [
      {
        type: 'success',
        background: 'green',
        duration:3000,
        dismissible:true,
        position: {
            x: 'center',
            y: 'top',
          },
      },
      {
        type: 'warning',
        background: 'yellow',
        duration:3000,
        dismissible:true,
        position: {
            x: 'center',
            y: 'top',
          },
      },
      {
        type: 'error',
        background: 'indianred',
        duration: 3000,
        dismissible: true,
        position: {
            x: 'center',
            y: 'top',
          },
      }
    ]}
);

export default notyf;