import React,{Component} from 'react';

class CustomerTable extends Component{
    render(){
        const {content={}}=this.props;
        return(
            <div >
        {typeof content !=="undefined"&&content.length>0 &&
           <table style={{border:0, backgroundColor:"white",borderRadius:10, boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)"}}>
              <tbody>
                  <tr style={{height:30,backgroundColor:"#26d493",color:"#fff"}}>
                    <td></td>
                    <td >Customer Name </td>
                    <td>Mobile No</td>
                    <td>Email Id</td>
                    <td>Contact</td>
                   
                    
                  </tr>
                  {content.map((k,index)=>{
                      let date = new Date( Date.parse(k.created_at))   
                      return( 
                     <tr> 
                      <td>{index+1}</td>
                      <td>{k.username}</td>
                      <td>{k.Mobile}</td>
                      <td>{k.Email}</td>
                      <td>
                      <a href= {"mailto:" + k.Email} className="bold" style={{color:"#26d493"}}><i class="material-icons" style={{fontSize:20}} >contact_mail</i></a>&nbsp;
                      <a href={"https://wa.me/"+k.Mobile.replace('+','')} target ="_blank" style={{color:"#26d493"}}><i class="material-icons" style={{fontSize:20}} >forum</i></a>&nbsp;
                      <a href= {"tel:" +k.Mobile} className="bold" style={{color:"#26d493"}}><i class="material-icons" style={{fontSize:20}} >contact_phone</i></a>
                      </td>
                     </tr>
                          )})} 
               </tbody>
           </table>}
           {(typeof content ==="undefined"||content.length===0)&&
             <div>
              <p style={{textAlign:"center"}}>Admin,you didn't have any Customers Detail in  your page</p>

            </div>
           }
            </div>
        ) 
}
}
export default CustomerTable;