import React,{Component} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import utils from "../../utils/utils.js";
import Home from "../home";
import { TopBarLoader } from '../../component';
import FormTable from "../../component/formtable";
import { formsActions } from "../../actions";
import notyf from "../../utils/notification.js";



class Forms extends Component {
    constructor(props){
        super(props);
        this.state = {
            addFormValue:''
        };
        this.handleChange = this.handleChange.bind(this);
        this.addForm = this.addForm.bind(this);


        if(!utils.isLoggedIn()){
            window.location.href = "/login";
        }
    }

    handleChange(e){
        this.setState({[e.target.name]:e.target.value})
    }
    addForm(){
        const {dispatch} = this.props;
        let payload = {
            title: this.state.addFormValue
        }
        dispatch(formsActions.addNewForms(payload));
        dispatch(formsActions.getForms());
    }

    componentWillReceiveProps(nxtProps){  
        const {companies,forms,dispatch}=nxtProps;
      
        let companyPropsChanged = companies.status !== this.props.companies.status;
      
           if(companyPropsChanged && companies.status === "GET_SUCCESS")
           {
            dispatch(formsActions.getForms());
           }
           if(nxtProps.forms.status==="EDITING_FORMS_SUCCESS"||nxtProps.forms.status==="DELETE_FORMS_SUCCESS"){
               notyf.open({type:"success",message:forms.list.message})
               dispatch(formsActions.getForms())
           }
           if(nxtProps.forms.status==="EDITING_FORMS_ERROR"||nxtProps.forms.status==="DELETE_FORMS_ERROR"){
            notyf.open({type:"error",message:forms.list.message})
            dispatch(formsActions.getForms())
        }
    }


    render(){
        const {forms,dispatch}=this.props;
        return(
            <Home>
                <h1>Forms</h1>
                {/* <div className="upload-btn-wrapper" alt="This is premium access account only" style={{cursor:"pointer"}}>
                <i class="material-icons" style={{verticalAlign: "middle"}}>lock</i><button onClick={()=> alert("This is For Premium access Only")} >
                       <i className="material-icons" style={{verticalAlign:"top"}}>note_add</i>
                          Add Forms
                    </button>
                    </div> */}
             {(forms.status==="GET_REQUEST"||forms.status==="EDITING_FORMS_REQUEST"||forms.status==="DELETE_FORMS_REQUEST" )&&<TopBarLoader/>}
             {forms.status==="GET_SUCCESS" && <FormTable content={forms.list.message} dispatch={dispatch} />}
             <div>
                 <input type="text" placeholder="Enter Form Name" name="addFormValue" value={this.state.addFormValue} onChange={this.handleChange}/>
                 <button type="button" onClick={this.addForm}>Add Form</button> 
             </div>

            </Home>
        );
    }
}

function mapStateToProps(state) {
    const { admin, user,companies,forms } = state;
    return { admin, user ,companies,forms};
  }
  
export default withRouter(connect(mapStateToProps)(Forms));