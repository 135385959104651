//Redux

import {applyMiddleware, combineReducers, createStore} from "redux";
import ReduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import {persistReducer}  from "redux-persist";
//Navigation
import customer from "../actions/customerSlice";
import csv from "../actions/csvSlice";
import { admin } from "./admin.reducer";
import { authentication } from "./authentication.reducer";
import { companies } from "./companies.reducer";
import { endpoint } from "./endpoint.reducer";
import { forms } from "./forms.reducer";
import { settings } from "./settings.reducer";
import { taskcreate } from "./taskcreate.reducer";
import { user } from "./user.reducer";
import {widget} from "./widget.reducer";
import {task} from "./taskview.reducer";
import {taskstatus} from "./taskstatus.reducer";
import {blog} from "./blog.reducer";
import {image} from "./image.reducer";
import {question} from "./question.reducer";
import {option} from "./questionoption.reducer";
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["authentication", "admin","compaines"]
};

  const middleware = () => {
    return applyMiddleware(ReduxThunk);
   
  };

  const pReducer =persistReducer(
      persistConfig,
      combineReducers({
        admin,
        authentication,
        companies,
        endpoint,
        forms,
        settings,
        taskcreate,
        user,
        widget,
        task,
        taskstatus,
        blog,
        image,
        question,
        option,
        csv,
        customer
      })
  );

  export default createStore(pReducer, middleware());