import React from "react";
import { connect } from "react-redux";
import { endpointActions } from "../../actions";
import {withRouter} from "react-router-dom";
import { TopBarLoader } from '../../component';

class RootLoader extends React.Component {
  constructor() {
    super();
    this.state = {};

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    const { dispatch, history } = this.props;
    // history.push("/login");
    dispatch(endpointActions.ping());
  }


  componentDidUpdate(prevProps) {
    const { history } =this.props;
    let newProps = this.props;
    let propsChanged = prevProps.endpoint.status !== newProps.endpoint.status;


    if (newProps.endpoint.status === "PING_SUCCESS" && propsChanged) {
      history.push("/login");
    }
  }

  render() {
    return <div style={{textAlign: "center"}}><TopBarLoader/></div>;
  }
}

function mapStateToProps(state) {
  const { endpoint } = state;
  return {
      endpoint
  };
}

export default withRouter(connect(mapStateToProps)(RootLoader));
