import React,{Component} from 'react';
import { imageActions } from '../../actions';
import appinfo from "../../app.json";
import TextInput from '../textInput';
class FileTable extends Component{

//  copyclipboard=()=>{
//     let text=document.querySelector(".text")
//     const textToCopy = document.querySelector(".text")
//     const selection = window.getSelection();
//     const range = document.createRange();
    
//     range.selectNodeContents(textToCopy);
//     selection.removeAllRanges();
//     selection.addRange(range);
    
//     document.execCommand('copy');
//     selection.removeAllRanges();
//    }
  onDelete=(id)=>{
    const {dispatch={}}=this.props;
    dispatch(imageActions.deleteImage(id));
}
    render(){
        const {content={}}=this.props;
        return(
           <div>
           {content.length>0 && 
           <table style={{border:0, backgroundColor:"white",borderRadius:10, boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)"}}>
              <tbody>
                  <tr style={{height:30,backgroundColor:"#26d493",color:"#fff"}}>
                    <td></td>
                    <td >Image</td>
                    <td>URL</td>
                    <td>Delete</td>
                    
                  </tr>
                  {content.map((k,index)=>{
                      return( 
                     <tr> 
                      <td>{index+1}</td>
                      <td><img src={appinfo.url+`/admin/image/`+k}style={{height:50,borderRadius:0,width:70}}/></td>
                      <td ><TextInput value={appinfo.url+`/admin/image/`+k} style={{padiing:0}} disabled/></td>
                      <td onClick={()=>this.onDelete(k)}>
                         <i class="material-icons" style={{color:"#f03a3a",cursor:"pointer",bottom:10}}>delete_forever</i>
                    </td>
                     </tr>
                          )})} 
               </tbody>
           </table>}
           {content.length===0&&
             <div>
              <p style={{textAlign:"center"}}>Admin,you didn't have any images in your pages</p>
              <p style={{textAlign:"center"}}>Make an image upload to get an url.</p>

            </div>
           }
           </div>
        )
    }
}
export default FileTable;