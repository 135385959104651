import {httpClient} from "../utils";


export const imageService={
      uploadimage,
      getallimage,
      deleteimage
}


function uploadimage(file){
const data = new FormData()
    data.append("image", file)
    data.append("filename",file.name)
    return httpClient.callApi("POST",`/admin/upload`,data,false,true)
}

function getallimage(){
  return httpClient.callApi("GET",`/admin/list_images`)
}

function deleteimage(imagename){
     return httpClient.callApi("DELETE",`/admin/image/${imagename}`);
}