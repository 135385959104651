import {taskviewConstants} from "../constants";


const initialState ={
    task:[],
    status:"TASKVIEW_REQUEST"
};

export function task(state =initialState,action){
    switch (action.type){
        case taskviewConstants.TASKVIEW_REQUEST:
            return {
                ...state,
                status:"TASKVIEW_REQUEST",
                status:action.refresh ? "TASKVIEW_REQUEST_REFRESH":"TASKVIEW_REQUEST"
            };
        case taskviewConstants.TASKVIEW_SUCCESS:
            let tasks=action.task.tasks;
            let sortedtask;
            if(typeof tasks!=="undefined"){
               sortedtask=tasks.reverse()
            }
            else {
                sortedtask=tasks
            }
            return {
                ...state,
                status:"TASKVIEW_SUCCESS",
                task:sortedtask
            };

        case taskviewConstants.TASKVIEW_ERROR:
            return {
                ...state,
                status:"TASKVIEW_ERROR",
                task:action.error
            };
        case taskviewConstants.CHANGE_TASKSTATUS_REQUEST:
            return {
                ...state,
                status:"CHANGE_TASKSTATUS_REQUEST"
            };
        case taskviewConstants.CHANGE_TASKSTATUS_SUCCESS:
            return {
                ...state,
                status:"CHANGE_TASKSTATUS_SUCCESS",
                task:action.task 
            };
        case taskviewConstants.CHANGE_TASKSTATUS_ERROR:
            return {
                ...state,
                status:"CHANGE_TASKSTATUS_ERROR",
                task:action.error
            }
        default:
            return state;
    }
}