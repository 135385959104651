import {httpClient} from "../utils";

export const authenticationService ={
    isAvailable,
    login,
    logout,
    sendOtp,
    signup,
    signupPassword

}

function isAvailable(payload){
    return httpClient.callApi("POST",`/is_available`,payload);
}

function sendOtp(payload){
    return httpClient.callApi("POST",`/user/send_otp`,payload);
}

function login(payload, to = "login"){
    return httpClient.callApi( to == "login" ? "GET" : "POST",`/user/${to}`,payload)
}

function signup(payload){
    return httpClient.callApi("POST",`/user/create_account_verify_otp`,payload);
}

function signupPassword(payload){
    return httpClient.callApi("POST",`user/create_account`,payload);
}

function logout() {}