import {imageConstants} from "../constants";

const initialState ={
    images:[]
};

export function image(state=initialState,action){
    switch(action.type){
        case imageConstants.IMAGE_REQUEST:
            return{
                ...state,
                status:"UPLOADING_IMAGE_REQUEST"
            }
        case imageConstants.IMAGE_SUCCESS:
            return{
                ...state,
                status:"UPLOADING_IMAGE_SUCCESS",
                images:action.message
            }
        case imageConstants.IMAGE_ERROR:
            return {
                ...state,
                status:"UPLOADING_IMAGE_ERROR",
                images:action.error
            }
        case imageConstants.ALL_REQUEST:
            return{
                ...state,
                status:"ALL_IMAGE_REQUEST"
            }
        case imageConstants.ALL_SUCCESS:
            return {
                ...state,
                status:"ALL_IMAGE_SUCCESS",
                images:action.message
            }
        case imageConstants.ALL_ERROR:
            return {
                ...state,
                status:"ALL_IMAGE_ERROR",
                images:action.error
            }
        case imageConstants.DELETE_REQUEST:
            return {
                ...state,
                status:"DELETE_IMAGE_REQUEST",
            }
        case imageConstants.DELETE_SUCCESS:
            return {
                    ...state,
                    status:"DELETE_IMAGE_SUCCESS",
                    images:action.message
                }
         case imageConstants.DELETE_ERROR:
              return{
                        ...state,
                        status:"DELETE_IMAGE_ERROR",
                        images:action.error
                 }
        default:
            return state;
    }
}